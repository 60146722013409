import React from "react";
import { Helmet } from "react-helmet";
function ContactMeta() {
  return (
    <div>
      <Helmet>
        <title>Contact Dream Adoration: Get in Touch with Us</title>
        <meta
          name="description"
          content="Reach out to Dream Adoration for any inquiries, support, or collaboration opportunities. We're here to assist you with your needs and provide the best possible service. Contact us today!"
        />
        <meta
          name="keywords"
          content="Contact, Dream Adoration, Support, Inquiries, Collaboration, Customer Service, Help, Assistance, Reach Out"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/contact" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Contact Dream Adoration: Get in Touch with Us"
        />
        <meta
          property="og:description"
          content="Reach out to Dream Adoration for any inquiries, support, or collaboration opportunities. We're here to assist you with your needs and provide the best possible service. Contact us today!"
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/ContactCoverImage.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/ContactCoverImage.png"
        />
        <meta property="og:image:alt" content="Contact Dream Adoration" />
        <meta property="og:url" content="https://dreamadoration.com/contact" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dreamadoration/"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact Dream Adoration: Get in Touch with Us"
        />
        <meta
          name="twitter:description"
          content="Reach out to Dream Adoration for any inquiries, support, or collaboration opportunities. We're here to assist you with your needs and provide the best possible service. Contact us today!"
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/ContactCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default ContactMeta;
