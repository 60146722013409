import React from "react";
import "./CSS/preloader.css";
function Preloader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-filter backdrop-blur-sm z-50">
      <div class="wrapper">
        <div class="blue ball"></div>
        <div class="red ball"></div>
        <div class="yellow ball"></div>
        <div class="green ball"></div>
      </div>
    </div>
  );
}

export default Preloader;
