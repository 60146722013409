import React from "react";
import { Helmet } from "react-helmet";

function MockTestMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration: Mock Test Services</title>
        <meta
          name="description"
          content="Enhance your skills and prepare for success with Dream Adoration's comprehensive mock test services. Practice HTML, CSS, JavaScript, and more to excel in your IT career."
        />
        <meta
          name="keywords"
          content="Mock Test, Practice Test, HTML, CSS, JavaScript, IT Skills, Dream Adoration, Test Preparation, Skill Enhancement"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/mocktest" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Dream Adoration: Mock Test Services"
        />
        <meta
          property="og:description"
          content="Enhance your skills and prepare for success with Dream Adoration's comprehensive mock test services. Practice HTML, CSS, JavaScript, and more to excel in your IT career."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/MockTestCoverImage.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/MockTestCoverImage.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration Mock Test Services"
        />
        <meta
          property="og:url"
          content="https://www.dreamadoration.com/mocktest"
        />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration: Mock Test Services"
        />
        <meta
          name="twitter:description"
          content="Enhance your skills and prepare for success with Dream Adoration's comprehensive mock test services. Practice HTML, CSS, JavaScript, and more to excel in your IT career."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/MockTestCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default MockTestMeta;
