import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is Material-UI?",
    answer:
      "Material-UI is a popular React component library that provides pre-designed and customizable UI components following Google's Material Design guidelines. It offers a wide range of components such as buttons, cards, navigation bars, and more, making it easier for developers to create visually appealing and consistent user interfaces.",
  },
  {
    question: "How do you install Material-UI in a React project?",
    answer:
      "To install Material-UI in a React project, you can use npm or yarn. Run the following command in your project directory: 'npm install @mui/material @emotion/react @emotion/styled'. This will install Material-UI along with its required dependencies. After installation, you can start using Material-UI components in your React application.",
  },
  {
    question: "What are the advantages of using Material-UI?",
    answer:
      "Using Material-UI offers several advantages, including: consistent and visually appealing UI design, reduced development time with pre-built components, easy customization using theming and styling options, excellent documentation and community support, seamless integration with React applications, and adherence to Material Design principles for better user experience.",
  },
  {
    question: "Can Material-UI be used with other UI frameworks?",
    answer:
      "Yes, Material-UI can be used alongside other UI frameworks or libraries in React applications. It provides flexibility and interoperability, allowing developers to combine Material-UI components with components from other libraries to achieve their desired UI design and functionality.",
  },
  {
    question: "How do you customize themes in Material-UI?",
    answer:
      "You can customize themes in Material-UI using the 'createTheme' function and 'ThemeProvider' component. Define your custom theme using 'createTheme' and wrap your application with 'ThemeProvider', passing the custom theme as a prop. This allows you to override default styles and apply consistent theming across your application.",
  },
  {
    question: "What is the purpose of the 'makeStyles' hook in Material-UI?",
    answer:
      "The 'makeStyles' hook in Material-UI is used to create custom styles for your components. It allows you to define styles using JavaScript objects and apply them to components by calling the hook and attaching the returned classes to your component's elements. This enables you to write modular and reusable CSS-in-JS styles.",
  },
  {
    question: "How do you use Material-UI icons in a React project?",
    answer:
      "To use Material-UI icons in a React project, you need to install the '@mui/icons-material' package. You can then import and use individual icons as React components. For example, to use the 'Home' icon, you would import it with 'import HomeIcon from '@mui/icons-material/Home'' and include it in your JSX as '<HomeIcon />'.",
  },
  {
    question: "What is the Grid component in Material-UI?",
    answer:
      "The Grid component in Material-UI is a powerful layout system that allows you to create responsive and flexible grid-based layouts. It is based on the CSS Flexbox layout model and provides a set of properties for defining the grid structure, spacing, alignment, and responsive behavior. The Grid component helps create consistent and adaptive layouts across different screen sizes.",
  },
  {
    question: "How do you handle form validation with Material-UI?",
    answer:
      "Material-UI does not include built-in form validation, but it can be easily integrated with form validation libraries such as Formik or React Hook Form. You can use Material-UI's form components (like TextField, Checkbox, etc.) in combination with these libraries to handle validation, error messages, and form submission.",
  },
  {
    question:
      "What is the difference between Box and Container components in Material-UI?",
    answer:
      "The Box component in Material-UI is a utility component that allows you to apply CSS styles, spacing, and layout properties directly to an element. It is highly flexible and can be used to create custom layouts. The Container component, on the other hand, is a layout component that centers and constrains the content within fixed or fluid widths, providing responsive behavior and consistent padding.",
  },
  {
    question: "How do you create a dialog in Material-UI?",
    answer:
      "To create a dialog in Material-UI, you can use the 'Dialog' component along with 'DialogTitle', 'DialogContent', 'DialogActions', and other related components. You control the open state of the dialog using a boolean state variable and pass it to the 'open' prop of the Dialog component. You can customize the dialog's content, actions, and appearance using these components and props.",
  },
  {
    question: "What is the Typography component in Material-UI?",
    answer:
      "The Typography component in Material-UI is used to style and format text content. It provides a wide range of preset styles for headings, body text, captions, and other text elements, following Material Design guidelines. You can customize the text styles using props like 'variant', 'align', 'color', and 'gutterBottom'.",
  },
  {
    question: "How do you apply global styles in Material-UI?",
    answer:
      "To apply global styles in Material-UI, you can use the 'GlobalStyles' component or the 'CssBaseline' component. 'GlobalStyles' allows you to define global CSS styles that apply to the entire application. 'CssBaseline' is a ready-to-use component that provides a set of global CSS resets and base styles to normalize browser styling and ensure consistent rendering across different browsers.",
  },
  {
    question: "What are Material-UI system utilities?",
    answer:
      "Material-UI system utilities are a set of functions and components that help you build custom designs and layouts using a consistent and flexible approach. They include style functions for spacing, color, typography, breakpoints, and more. The system utilities allow you to create custom components with a consistent design language and responsive behavior, using a declarative and reusable approach.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function MaterialUIFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>Material-UI FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/materialuifaqs.jpg"
                alt="materialui"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18" // Adjusted width
                          height="18" // Adjusted height
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MaterialUIFAQS;
