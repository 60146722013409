import React from "react";
import htmlimg from "../Image/Book/html.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function HtmlBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              HTML Mastery Unleashing the Power of Web Design
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Aman Kumar
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              HTML Essentials" provides crucial insights for web development,
              enabling you to craft engaging websites. Download this book to
              enhance your understanding of HTML and expand your knowledge in
              web development. Unlock the fundamentals of HTML and empower
              yourself to create captivating web experiences. Dive into the
              world of web design with this comprehensive guide, tailored for
              beginners and seasoned developers alike. Elevate your skills and
              unleash your creativity with "HTML Essentials".
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default HtmlBook;
