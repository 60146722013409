import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/testimonial.css";

const data = [
  {
    name: "Aman",
    img: "Image/testimonials/man.png",
    alt: "image1",
    review:
      "Dream Adoration students laud its transformative impact, praising its personalized learning approach and supportive community. Through dedicated mentorship and tailored resources, Dream Adoration empowers students to thrive academically and beyond, fostering a culture of achievement and growth. These testimonials attest to Dream Adoration's unwavering commitment to student success and holistic development.",
  },
  {
    name: "Shivam",
    img: "Image/testimonials/people.png",
    alt: "image1",
    review:
      "Dream Adoration is a game-changer! Its innovative platform and engaging curriculum have revolutionized my learning experience. The personalized approach and supportive community have propelled me towards my academic goals, making learning enjoyable and rewarding. Dream Adoration's commitment to excellence and student success truly sets it apart.",
  },
  {
    name: "Neha",
    img: "Image/testimonials/avatar.png",
    alt: "image1",
    review:
      "Dream Adoration has been an incredible journey for me. The interactive lessons and expert guidance have helped me grasp complex concepts with ease. The platform's user-friendly interface and diverse learning resources have made my learning experience enjoyable and effective. I highly recommend Dream Adoration to anyone looking to elevate their skills and achieve their goals.",
  },
  {
    name: "Simran",
    img: "Image/testimonials/human.png",
    alt: "image1",
    review:
      "Dream Adoration has been a game-changer for me. The interactive lessons and real-world projects have boosted my confidence in coding. The platform's supportive community and expert mentors have provided invaluable guidance throughout my journey. Thanks to Dream Adoration, I've not only mastered new skills but also discovered my passion for programming.",
  },
  {
    name: "Kuldip",
    img: "Image/testimonials/men3.jpg",
    alt: "image1",
    review:
      "Dream Adoration has changed how I learn in amazing ways. The personalized lessons and thorough curriculum have helped me understand many subjects better. The platform is easy to use and its interactive features make learning fun. I'm grateful for the opportunity to grow and succeed with Dream Adoration.",
  },
];

function Ourstudentsay() {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const toggleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div
      className="testimonial-container"
      style={{
        backgroundColor: "#0d1232",
        padding: "2rem",
        margin: "3rem",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      <h2 className="text-white" style={{ fontSize: "18px" }}>
        <span style={{ fontSize: "1.5rem" }}>Voices of Success</span>
        <br />
        <span style={{ fontSize: "2rem" }}>
          Hear What Our Students{" "}
          <span style={{ color: "red" }}>Have to Say</span>
        </span>
      </h2>
      <div className="w-3/4 m-auto">
        <div className="mt-10">
          <Slider {...settings}>
            {data.map((d, index) => (
              <div
                key={index}
                className="slide-testimonial bg-white text-black rounded-xl overflow-hidden"
                style={{ height: "400px" }}
              >
                <div className="h-46 rounded-t-xl bg-indigo-500 flex justify-center items-center">
                  <img src={d.img} alt="" className="h-44 w-44 rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center gap-1 p-4 overflow-auto">
                  <p className="text-xl font-semibold">{d.name}</p>
                  {expandedIndex === index ? (
                    <p>{d.review}</p>
                  ) : (
                    <p>
                      {d.review.length > 75
                        ? d.review.slice(0, 75) + "..."
                        : d.review}
                    </p>
                  )}
                  <button
                    onClick={() => toggleExpand(index)}
                    className="bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl read-more-button"
                  >
                    {expandedIndex === index ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Ourstudentsay;
