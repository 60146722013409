import React from "react";
import { Helmet } from "react-helmet";
function PrimeTouchMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration Prime Touch</title>
        <meta
          name="description"
          content="Discover Dream Adoration's Prime Touch program designed to elevate your web development skills. Join us for transformative internships, live projects, mock test services, and more. Start your journey today!"
        />
        <meta
          name="keywords"
          content="Prime Touch, Web Development, Internship, Live Projects, Mock Test, Dream Adoration, Skill Enhancement, Dream Realization, Innovation"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://dreamadoration.com/primetouch" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Dream Adoration Prime Touch" />
        <meta
          property="og:description"
          content="Discover Dream Adoration's Prime Touch program designed to elevate your web development skills. Join us for transformative internships, live projects, mock test services, and more. Start your journey today!"
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration Prime Touch Program Cover"
        />
        <meta
          property="og:url"
          content="https://dreamadoration.com/primetouch"
        />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dream Adoration Prime Touch" />
        <meta
          name="twitter:description"
          content="Discover Dream Adoration's Prime Touch program designed to elevate your web development skills. Join us for transformative internships, live projects, mock test services, and more. Start your journey today!"
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/PrimeTouchCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default PrimeTouchMeta;
