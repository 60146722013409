import React from "react";

function About() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div
        className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl"
        style={{
          margin: "5rem",
          backgroundColor: "#F4F4F6",
          borderRadius: "1rem",
        }}
      >
        <div
          className="font-light text-gray-500 sm:text-lg dark:text-gray-400"
          style={{
            backgroundColor: "#0b102b",
            borderRadius: "1rem",
            padding: "1rem",
          }}
        >
          <h2
            className="mb-4 text-4xl tracking-tight font-extrabold dark:text-white"
            style={{ margin: "1rem" }}
          >
            Welcome to Dream Adoration!
          </h2>
          <p className="mb-4" style={{ fontWeight: "600" }}>
            At Dream Adoration, we believe in the power of learning and the
            transformative journey it offers. We are a team of dedicated
            professionals, driven by a passion for education and a commitment to
            nurturing talent. As the{" "}
            <span style={{ fontWeight: "bolder" }}>CEO, I, Aman Kumar,</span>{" "}
            take pride in our mission to provide exceptional learning courses
            and internship opportunities.
          </p>
          <p style={{ fontWeight: "600" }}>
            With a background in mastering from BPUT, Bhubaneswar, and
            graduation from Bihar, I bring a wealth of experience and expertise
            to our organization. Our company ethos revolves around being
            innovators and problem solvers, always striving to push boundaries
            and unlock new potentials.
          </p>
          <p style={{ fontWeight: "600" }}>
            At Dream Adoration, we offer a range of services designed to support
            your educational and professional growth:
          </p>
          <ul
            style={{
              fontWeight: "600",
              marginLeft: "1rem",
              listStyleType: "disc",
            }}
          >
            <li>
              <strong>Internships:</strong> Our internships are tailored for
              students and professionals in the IT sector. Gain hands-on
              experience by working on real-time projects, enhancing your skills
              and boosting your career prospects.
            </li>
            <li>
              <strong>Learning Resources:</strong> We provide eBooks and a rich
              repository of interview preparation materials, including interview
              questions and mock tests, to help you succeed in your job search.
            </li>
            <li>
              <strong>Resume Enhancement:</strong> Learn the best practices for
              creating impactful resumes that stand out to potential employers.
            </li>
            <li>
              <strong>Project Development:</strong> If you need assistance with
              any project, our team of experts is here to help. We work on a
              variety of projects, providing tailored solutions to meet your
              specific needs.
            </li>
          </ul>
          <p style={{ fontWeight: "600" }}>
            Dream Adoration is agile and robust, offering personalized attention
            and quick responses while delivering comprehensive learning
            experiences. Whether you're starting your educational journey or
            looking to advance your skills, we're here to guide you every step
            of the way.
          </p>
          <p style={{ fontWeight: "600" }}>
            Join us at Dream Adoration and embark on a journey of learning,
            growth, and success. Together, let's turn dreams into reality.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
