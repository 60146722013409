import React from "react";
import pocketexam from "../Data/Goverment/IIT/IIT.json";

function IIT() {
  return (
    <div>
      <br />
      <br />
      <br />

      <h1
        class="flex justify-center font-serif text-center"
        style={{ margin: "0.7rem" }}
      >
        IIT (Indian Institutes of Technology)
      </h1>

      <div
        className="flex flex-wrap justify-center"
        style={{ backgroundColor: "#f4f4f6", borderRadius: "1rem" }}
      >
        {pocketexam.map((service, index) => (
          <div style={{ margin: "1rem" }}>
            <a
              href="#"
              key={index}
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <img
                class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
                src={service.IMG}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white font-serif">
                  {service.Group}
                </h5>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 font-serif">
                  {service.desc}
                </p>

                <a
                  href={service.href}
                  className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {service.BTN}
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns=""
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default IIT;
