import React from "react";
import htmlimg from "../Image/Book/javascript.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function JavaScriptBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              JavaScript
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Dr. Axel Rauschmayer
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Here's a description for JavaScript: Embark on a journey of
              dynamic web development with 'JavaScript Unleashed.' Explore the
              limitless possibilities of JavaScript and breathe life into your
              web projects. This comprehensive guide equips you with the
              knowledge and skills to harness the full potential of JavaScript,
              from basic scripting to advanced techniques. Dive into the world
              of interactivity, animation, and dynamic content creation. Whether
              you're a novice or a seasoned developer, 'JavaScript Unleashed' is
              your go-to resource for mastering this versatile programming
              language. Elevate your web applications, captivate your audience,
              and unleash the true power of JavaScript with 'JavaScript
              Unleashed'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default JavaScriptBook;
