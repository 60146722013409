import React, { useState } from "react";

function RailwayCourse() {
  const [modalData, setModalData] = useState({
    isOpen: false,
    videoSrc: "",
  });

  const openModal = (videoSrc) => {
    setModalData({
      isOpen: true,
      videoSrc: videoSrc,
    });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      videoSrc: "",
    });
  };

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col items-center justify-center p-4">
      <h1 className="mb-8 text-3xl font-bold tracking-tight text-center text-gray-900 dark:text-white">
        Railway
      </h1>

      <a
        href="#"
        className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        <img
          className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
          src="Image/Course/Railway/Kundan/Railway.jpg"
          alt="Railway 3250 Math Book Solution"
        />
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Railway 3250 Math Book Solution
          </h5>

          <h5 className="mb-2 text-sm font-semibold tracking-tight text-gray-900">
            Name: Kundan
          </h5>
          <h5 className="mb-2 text-sm font-semibold tracking-tight text-gray-900 flex items-center">
            <span className="mr-2">&#8377;150</span>
            <span className="line-through mr-4 text-gray-500">&#8377;800</span>
          </h5>
          <span className="flex items-center mt-2 mb-2">
            <i className="fas fa-star text-yellow-500"></i>
            <i className="fas fa-star text-yellow-500"></i>
            <i className="fas fa-star text-yellow-500"></i>
            <i className="fas fa-star text-yellow-500"></i>
            <i className="fas fa-star-half-alt text-yellow-500"></i>
          </span>

          <button
            type="button"
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 me-2 mb-2 mt-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 flex items-center justify-center"
            onClick={() =>
              openModal(
                "Video/Railway_3250_Math_Book_Solution_ALP_TECH_RPF_JE_NTPC_GROUP_D_17.mp4"
              )
            }
          >
            <box-icon
              name="video"
              type="solid"
              color="#f1f4f0"
              className="mr-2"
            ></box-icon>
            <span>Watch Now</span>
          </button>

          <button
            type="button"
            className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center justify-center"
          >
            <box-icon
              name="cart-add"
              type="solid"
              color="#f1f4f0"
              className="mr-2"
            ></box-icon>
            <span>Buy Now</span>
          </button>
        </div>
      </a>

      {modalData.isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-filter backdrop-blur-sm"
          onClick={closeModal}
        >
          <div
            className="relative w-full h-full sm:w-3/4 sm:h-3/4 lg:w-1/2 lg:h-1/2 p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative w-full h-full flex justify-center items-center">
              <video
                src={modalData.videoSrc}
                controls
                poster="images/post-1-1.png"
                className="w-full h-auto"
              ></video>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RailwayCourse;
