import React from "react";
import htmlimg from "../Image/Book/mysql.png";
import DownloadEbookForm from "../DownloadEbookForm";

function MySQlBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              MySQL Mastery for Modern Databases
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Aman Kumar
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Sure, here's a description for MySQL: Dive into the world of
              database management with 'MySQL Mastery.' Unravel the complexities
              of MySQL and harness its full potential to build robust and
              scalable data-driven applications. This comprehensive guide
              provides you with the knowledge and tools to design, optimize, and
              maintain MySQL databases effectively. From querying and indexing
              to transactions and replication, 'MySQL Mastery' covers it all.
              Whether you're a novice or an experienced developer, this book
              equips you with the skills to leverage MySQL's power to its
              fullest. Elevate your database management skills, streamline your
              workflows, and unlock new possibilities with 'MySQL Mastery'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default MySQlBook;
