import React, { useState } from "react";
import Modal from "./Modal";
import RotatingAnimation from "./RotatingAnimation";
import video from "./Image/Video/HeroVideo.mp4";

function Slider() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Laptop/Desktop View */}
      <div className="hidden md:block relative">
        <video autoPlay loop muted className="w-full h-full object-cover">
          <source src={video} type="video/mp4" />
        </video>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white/50 rounded-md p-4 text-center flex flex-col items-center gap-8 w-[70%]">
          <h1 className="text-lg md:text-2xl lg:text-3xl font-extrabold">
            Dream Adoration
            <br />{" "}
            <span style={{ color: "#F1B434" }}>Where Dreams Meet Reality</span>
          </h1>

          <p className="text-sm md:text-base lg:text-lg font-bold">
            At Dream Adoration, we turn your dreams into reality. Elevate your
            life with our stylish and unique products.
          </p>

          <button
            type="button"
            class="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700"
            onClick={openModal}
          >
            Registration
          </button>
        </div>
      </div>

      {/* Mobile View */}
      <div
        className="block md:hidden"
        style={{ marginTop: "4rem", backgroundColor: "#0d1232" }}
      >
        <section className="dark:bg-gray-900 h-full lg:h-auto pb-24 lg:pb-0">
          <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid lg:grid-cols-12 flex flex-col lg:flex-row h-full">
            <div className="lg:col-span-5 flex justify-center lg:justify-end lg:mr-8 mb-6 lg:mb-12">
              <div className="w-full md:w-1/2 lg:w-full">
                <RotatingAnimation />
              </div>
            </div>
            <div className="flex flex-col justify-center lg:justify-center lg:col-span-7">
              <div className="text-center lg:text-left">
                <h1
                  className="max-w-2xl mb-4 text-4xl text-white font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white mt-3"
                  style={{ display: "-webkit-inline-box" }}
                >
                  Dream Adoration
                  <br />
                  <span style={{ color: "#BEA314" }}>
                    Where Dreams Meet Reality
                  </span>
                </h1>
                <p
                  className="max-w-2xl mb-6 font-semibold text-gray-200 lg:mb-8 md:text-lg lg:text-xl text-xl dark:text-gray-400"
                  style={{ display: "-webkit-inline-box" }}
                >
                  At Dream Adoration, we turn your dreams into reality. Elevate
                  your life with our stylish and unique products.
                </p>
                <div className="mb-6">
                  <button
                    type="button"
                    onClick={openModal}
                    className="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Registration
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Modal Component (shown in both views) */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Slider;
