import React from "react";
import htmlimg from "../Image/Book/php.png";
import DownloadEbookForm from "../DownloadEbookForm";

function PhpBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              PHP
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : WCGs serve
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Certainly! Here's a description for PHP: Dive into the dynamic
              world of web development with 'PHP Powerhouse.' Uncover the power
              and versatility of PHP, the backbone of countless websites and web
              applications. This comprehensive guide equips you with the skills
              and knowledge to leverage PHP's full potential, from basic
              scripting to advanced web development techniques. Discover how to
              build dynamic websites, interact with databases, and create robust
              web applications with ease. Whether you're a novice or an
              experienced developer, 'PHP Powerhouse' is your go-to resource for
              mastering PHP and creating dynamic web experiences. Elevate your
              coding skills, unleash your creativity, and unlock the true power
              of PHP with 'PHP Powerhouse'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default PhpBook;
