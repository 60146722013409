import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is JavaScript?",
    answer:
      "JavaScript is a high-level, interpreted programming language primarily used for adding interactivity and dynamic behavior to web pages. It is commonly used alongside HTML and CSS to create interactive web applications and dynamic content.",
  },
  {
    question: "What are the key features of JavaScript?",
    answer:
      "JavaScript has several key features, including: being lightweight and interpreted, supporting both object-oriented and functional programming paradigms, providing built-in support for manipulating HTML and CSS, enabling asynchronous programming with callbacks and promises, and being versatile enough to run on both the client-side (browser) and server-side (Node.js).",
  },
  {
    question: "How do you declare variables in JavaScript?",
    answer:
      "Variables in JavaScript can be declared using the 'var', 'let', or 'const' keywords. 'var' is used for declaring variables with function scope, 'let' is used for block-scoped variables that can be reassigned, and 'const' is used for block-scoped variables that cannot be reassigned after initialization.",
  },
  {
    question: "What are JavaScript data types?",
    answer:
      "JavaScript has several data types, including: primitive data types such as numbers, strings, booleans, null, undefined, and symbols, and complex data types such as objects (including arrays and functions). Additionally, JavaScript is dynamically typed, meaning variables can hold values of any type without explicit type declarations.",
  },
  {
    question: "What is the difference between '==' and '===' in JavaScript?",
    answer:
      "'==' is the equality operator that performs type coercion, meaning it compares values after converting them to a common type. '===' is the strict equality operator that compares both the value and the type, so no type conversion is performed.",
  },
  {
    question: "What is a JavaScript function?",
    answer:
      "A JavaScript function is a reusable block of code designed to perform a particular task. Functions are defined using the 'function' keyword, followed by a name, parentheses (which may include parameters), and a block of code enclosed in curly braces. For example: 'function myFunction() { // code to be executed }'",
  },
  {
    question: "What is a closure in JavaScript?",
    answer:
      "A closure is a feature in JavaScript where an inner function has access to its own scope, the scope of the outer function, and the global scope. Closures are created whenever a function is created within another function, allowing the inner function to remember the environment in which it was created.",
  },
  {
    question: "What is the DOM in JavaScript?",
    answer:
      "The DOM (Document Object Model) is a programming interface for web documents. It represents the structure of an HTML or XML document as a tree of objects, allowing JavaScript to manipulate the content, structure, and style of web pages. The DOM provides methods for accessing and updating elements, attributes, and events.",
  },
  {
    question: "What are JavaScript promises?",
    answer:
      "JavaScript promises are objects that represent the eventual completion (or failure) of an asynchronous operation and its resulting value. Promises provide a cleaner and more manageable way to handle asynchronous operations compared to callbacks. They have three states: pending, fulfilled, and rejected.",
  },
  {
    question: "How do you handle errors in JavaScript?",
    answer:
      "Errors in JavaScript can be handled using 'try...catch' statements. The 'try' block contains the code that may throw an error, and the 'catch' block contains the code to handle the error if it occurs. Additionally, there is a 'finally' block that executes regardless of whether an error was thrown or not.",
  },
  {
    question: "What is 'this' keyword in JavaScript?",
    answer:
      "The 'this' keyword in JavaScript refers to the context in which a function is executed. Its value depends on how the function is called. In the global context, 'this' refers to the global object (window in browsers). In a method, 'this' refers to the object the method belongs to. In an event handler, 'this' refers to the element that received the event.",
  },
  {
    question: "What is event delegation in JavaScript?",
    answer:
      "Event delegation is a technique in JavaScript where a single event listener is added to a parent element to manage events for multiple child elements. Instead of adding separate event listeners to each child, the parent element handles the events and determines the target element using 'event.target'. This improves performance and simplifies event handling.",
  },
  {
    question: "What are JavaScript modules?",
    answer:
      "JavaScript modules are reusable pieces of code that can be imported and exported between different files. Modules allow you to organize and structure your code better by encapsulating functionality and dependencies. Modern JavaScript uses ES6 module syntax with 'import' and 'export' statements.",
  },
  {
    question: "How do you create an object in JavaScript?",
    answer:
      "Objects in JavaScript can be created using object literals, constructors, or the 'Object.create()' method. An object literal is defined using curly braces: 'let obj = { key: value };'. A constructor function creates objects using the 'new' keyword: 'function Person(name) { this.name = name; } let person = new Person('John');'. 'Object.create(proto)' creates an object with the specified prototype.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function JavaScriptFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>JavaScript FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/javascriptfaqs.jpg"
                alt="javascript"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 15.5L5.5 9L6.91 7.59L12 12.67L17.09 7.59L18.5 9L12 15.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JavaScriptFAQS;
