import React from "react";
import logo from "./Image/Dreamadoration.png";
// import { Link } from 'react-router-dom';
import "./CSS/footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BoxIcon } from "boxicons";

function Footer() {
  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <footer className="text-center text-lg-start bg-body-tertiary text-muted">
      {/* Section: Social media */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        {/* Left */}
        <div className="me-5 d-none d-lg-block">
          <span>
            <b>Get connected with us on social networks:</b>
          </span>
        </div>
        {/* Left */}

        {/* Right */}
        <div>
          <a
            data-mdb-ripple-init
            className="btn text-white btn-floating m-1"
            style={{ backgroundColor: "#3b5998" }} // Convert string to object
            href="https://www.facebook.com/profile.php?id=61560307265465"
            role="button"
          >
            <box-icon
              name="facebook"
              type="logo"
              animation="tada"
              rotate="180"
              color="#fff"
            ></box-icon>
          </a>
          <a
            data-mdb-ripple-init
            className="btn text-white btn-floating m-1"
            style={{ backgroundColor: "#55acee" }} // Convert string to object
            href="https://x.com/dream_adoration"
            role="button"
          >
            <box-icon
              name="twitter"
              type="logo"
              rotate="180"
              animation="tada"
              color="#fff"
            ></box-icon>
          </a>

          <a
            data-mdb-ripple-init
            className="btn text-white btn-floating m-1"
            style={{ backgroundColor: "#ac2bac" }} // Convert string to object
            href="https://www.instagram.com/dream_adoration?utm_source=qr&igsh=YmNkZ2F5a2Qyb2gx"
            role="button"
          >
            <box-icon
              name="instagram"
              type="logo"
              rotate="180"
              animation="tada"
              color="#fff"
            ></box-icon>
          </a>
          <a
            data-mdb-ripple-init
            className="btn text-white btn-floating m-1"
            style={{ backgroundColor: "#0082ca" }} // Convert string to object
            href="https://www.linkedin.com/in/dream-adoration-60606930b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            role="button"
          >
            <box-icon
              name="linkedin"
              type="logo"
              animation="tada"
              rotate="180"
              color="#fff"
            ></box-icon>
          </a>
        </div>
        {/* Right */}
      </section>
      {/* Section: Social media */}

      {/* Section: Links  */}
      <section className="">
        <div className="container text-center text-md-start mt-5">
          {/* Grid row */}
          <div className="row mt-3">
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4">
                Services
                <br />
                {/* <a href="/">
                  <img
                    src={logo}
                    style={{
                      borderRadius: "100px",
                      maxWidth: "35%",
                      height: "auto",
                      display: "inline-block",
                      margin: "5px",
                    }}
                    alt="Dream Adoration"
                  />
                </a> */}
              </h6>
              <p>
                <a href="prime-touch" className="text-reset">
                  Internship
                </a>
              </p>

              <p>
                <a href="prime-touch" className="text-reset">
                  Work on Live Projects
                </a>
              </p>

              <p>
                <a href="prime-touch" className="text-reset">
                  New Projects to building website
                </a>
              </p>
              <p>
                <a href="mocktest" className="text-reset">
                  Mock Test
                </a>
              </p>
              <p style={{ marginTop: "-2rem" }}>
                {/* Dream Adoration: Empowering personalized learning. Explore, discover, excel. Mapping dreams to success. Join us in shaping education's future. */}
              </p>
            </div>
            {/* Grid column */}

            {/* Grid column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4">Courses</h6>
              <p>
                {/* <Link to="#html" className="text-reset">HTML</Link> */}
                <a href="html-learning" className="text-reset">
                  HTML
                </a>
              </p>
              <p>
                <a href="css-learning" className="text-reset">
                  Css
                </a>
              </p>
              <p>
                <a href="react-js-learning" className="text-reset">
                  React Js
                </a>
              </p>
              <p>
                <a href="php-learning" className="text-reset">
                  PHP
                </a>
              </p>
            </div>

            {/* Grid column */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4">Courses</h6>
              <p>
                <a href="bootstrap-learning" className="text-reset">
                  Bootstrap
                </a>
              </p>
              <p>
                <a href="python-learning" className="text-reset">
                  Python
                </a>
              </p>
              <p>
                <a href="mysql-learning" className="text-reset">
                  MySQL
                </a>
              </p>
              <p>
                <a href="javascript-learning" className="text-reset">
                  JavaScript
                </a>
              </p>
            </div>
            {/* Grid column */}

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4">Company</h6>
              <p>
                {/* <Link to="#html" className="text-reset">HTML</Link> */}
                <a href="/company" className="text-reset">
                  Company
                </a>
              </p>
              <p>
                {/* <Link to="/about" className="text-reset">About US</Link> */}
                <a href="/about" className="text-reset">
                  About Us
                </a>
              </p>
              <p>
                {/* <Link to="/about" className="text-reset">About US</Link> */}
                <a href="/terms-and-condition" className="text-reset">
                  Terms of use
                </a>
              </p>
              <p>
                {/* <Link to="/about" className="text-reset">About US</Link> */}
                <a href="/privacy&policy" className="text-reset">
                  Privacy Policy
                </a>
              </p>
            </div>

            {/* Grid column */}
            <div
              className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
              id="contact"
            >
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p style={{ display: "inline-flex", gap: "1rem" }}>
                <a
                  href="https://www.google.com/maps/place/25%C2%B049'18.5%22N+85%C2%B014'04.0%22E/@25.821813,85.2337883,19z/data=!3m1!4b1!4m13!1m8!3m7!1s0x39ed4301122acb37:0x96c7717fa0d1d5b7!2sGurmiya,+Bihar!3b1!8m2!3d25.8154252!4d85.2256635!16s%2Fg%2F12hpwws3m!3m3!8m2!3d25.821813!4d85.234432?entry=ttu"
                  target="_blank"
                >
                  <box-icon name="home" color="#e95231"></box-icon>
                </a>
                Bihar, Vaishali-844117
              </p>

              <p style={{ display: "inline-flex", gap: "1rem" }}>
                <a href="mailto:support@dreamadoration.com">
                  <box-icon name="mail-send" color="#8031e9"></box-icon>
                </a>
                support@dreamadoration.com
              </p>

              <p style={{ display: "inline-flex", gap: "1rem" }}>
                <a href="mailto:contact@dreamadoration.com">
                  <box-icon name="gmail" type="logo" color="#ee3030"></box-icon>
                </a>
                contact@dreamadoration.com
              </p>
              <p style={{ display: "inline-flex", gap: "1rem" }}>
                <a href="tel:9097951772">
                  <box-icon
                    name="phone-call"
                    animation="tada"
                    color="#3796da"
                  ></box-icon>
                </a>
                <span> +91 9097951772</span>
              </p>
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
      </section>
      {/* Section: Links  */}

      {/* Copyright */}
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        Copyright © {getCurrentYear()} Dream Adoration. All rights reserved.
      </div>
      {/* Copyright */}
    </footer>
    /* Footer */
  );
}

export default Footer;
