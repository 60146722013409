import React from "react";
import { Helmet } from "react-helmet";

function CourseMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration Course: Mastering Web Development</title>
        <meta
          name="description"
          content="Join our comprehensive web development course at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          name="keywords"
          content="Course, Web Development, HTML, CSS, JavaScript, ReactJS, Programming, Learning, Education, Dream Adoration, Web Development Course"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://dreamadoration.com/course" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Dream Adoration Course: Mastering Web Development"
        />
        <meta
          property="og:description"
          content="Join our comprehensive web development course at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration Web Development Course Cover"
        />
        <meta property="og:url" content="https://dreamadoration.com/course" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration Course: Mastering Web Development"
        />
        <meta
          name="twitter:description"
          content="Join our comprehensive web development course at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/CourseCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
      <div id="root"></div>
    </div>
  );
}

export default CourseMeta;
