import React, { useState } from "react";
import Modal from "./Component/Modal";
function GameChange() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="flex justify-center items-center text-center bg-gray-100 rounded-lg m-3 p-4">
      <div className="max-w-6xl p-6 border border-gray-900 rounded-lg shadow bg-gray-600 m-4">
        <p className="mb-3 font-bold text-white dark:text-white">
          The right time to turn your dreams into reality is now. By interning
          at Dream Adoration, you can give wings to your dreams and prepare for
          future success. Every opportunity to learn and grow is here. Chase
          your dreams.
        </p>

        <div className="mb-6">
          <button
            type="button"
            onClick={openModal}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Try For Free
          </button>
          <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
  );
}

export default GameChange;
