import React from "react";
import primetouch from "./Data/PrimeTouch.json"; // Import JSON data
import PrimeTouchMeta from "./Meta/PrimeTouchMeta";

function PrimeTouch() {
  return (
    <div style={{ marginTop: "3.5rem" }}>
      <PrimeTouchMeta />
      <div
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "10px",
        }}
      >
        <h2
          className="text-black"
          style={{ fontSize: "18px", textAlign: "center", padding: "1rem" }}
        >
          <span
            style={{ color: "blue", fontWeight: "bold", fontSize: "1.8rem" }}
          >
            Our Services
          </span>
          <br />
          <span style={{ fontSize: "1.4rem" }}>
            {" "}
            Our Dream Adoration Service is your companion in turning dreams into
            reality, enriching and treasuring them in vibrant hues! <br />
            <span style={{ color: "red" }}>What We Offer</span>
          </span>
        </h2>
        <div className="flex flex-wrap justify-center">
          {primetouch.map((service, index) => (
            <div
              key={index}
              className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-4"
            >
              <img
                src={service.image}
                alt=""
                style={{ borderRadius: "0.3rem" }}
              />

              <a href={service.href}>
                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                  {service.title}
                </h5>
                <p
                  style={{ fontWeight: "400" }}
                  className="mb-3 font-normal text-gray-500 dark:text-gray-400"
                >
                  {service.description}
                </p>
                <span className="inline-flex items-center text-blue-600 hover:underline">
                  {service.linkText}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PrimeTouch;
