import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is Python?",
    answer:
      "Python is a high-level, interpreted programming language known for its simplicity and readability. It supports multiple programming paradigms, including procedural, object-oriented, and functional programming. Python is widely used for web development, data analysis, artificial intelligence, scientific computing, and more.",
  },
  {
    question: "What are the key features of Python?",
    answer:
      "Python has several key features, including: easy-to-read syntax that emphasizes readability and reduces the cost of program maintenance, dynamic typing with strong type inference, extensive standard library with built-in modules and packages for various tasks, cross-platform compatibility, and support for both procedural and object-oriented programming.",
  },
  {
    question: "How do you define a function in Python?",
    answer:
      "In Python, a function is defined using the 'def' keyword followed by the function name and parameters enclosed in parentheses. The function body is indented and contains the code to be executed when the function is called. Functions can optionally return a value using the 'return' statement.",
  },
  {
    question: "What are Python's data types?",
    answer:
      "Python supports several built-in data types, including: integers, floating-point numbers, complex numbers, strings, lists, tuples, dictionaries, sets, and booleans. Additionally, Python allows for the creation of custom data types using classes and objects.",
  },
  {
    question: "How do you install Python?",
    answer:
      "To install Python, you can download the installer from the official Python website (python.org) and follow the installation instructions. Python is available for Windows, macOS, and Linux operating systems.",
  },
  {
    question: "What is pip in Python?",
    answer:
      "pip is the package installer for Python. It allows you to install and manage additional libraries and packages that are not included in the standard Python library. You can use pip to install packages from the Python Package Index (PyPI) and other repositories.",
  },
  {
    question: "What are Python decorators?",
    answer:
      "Decorators in Python are a powerful and flexible tool for modifying the behavior of functions or methods without changing their code. They are implemented using the @decorator syntax and are commonly used for logging, authorization, caching, and more.",
  },
  {
    question: "What is a Python virtual environment?",
    answer:
      "A Python virtual environment is a self-contained directory that contains a specific Python interpreter and a set of libraries. It allows you to create isolated environments for different projects, each with its own dependencies, without affecting the system-wide Python installation.",
  },
  {
    question: "How do you handle exceptions in Python?",
    answer:
      "In Python, exceptions are handled using try-except blocks. The code that may raise an exception is placed inside the try block, and the code to handle the exception is placed inside the except block. Optionally, you can use finally block to execute code regardless of whether an exception occurred or not.",
  },
  {
    question: "What are Python modules?",
    answer:
      "Python modules are files containing Python code that define functions, classes, and variables. They allow you to organize your Python code into reusable units. Modules can be imported into other Python scripts or interactive sessions using the 'import' statement.",
  },
  {
    question: "What is object-oriented programming (OOP) in Python?",
    answer:
      "Object-oriented programming (OOP) is a programming paradigm that emphasizes the creation of objects that encapsulate data and behavior. In Python, OOP is supported with classes and objects. Classes define blueprints for creating objects, while objects are instances of classes that can be manipulated and interact with each other.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq, index) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function PythonFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>Python FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/pythonfaqs.jpg"
                alt="python"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18" // Adjusted width
                          height="18" // Adjusted height
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PythonFAQS;
