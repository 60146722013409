import React from "react";
import ebook from "./Component/Image/socialmedia/ebook.png";
import mocktest from "./Component/Image/socialmedia/mocktest.png";
import blog from "./Component/Image/socialmedia/blog.png";

function HotPressRelease() {
  return (
    <div
      style={{
        backgroundColor: "#f4f4f6",
        margin: "1rem",
        padding: "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <h2 className="text-center text-4xl mb-4">Hot from the press!</h2>
      <div className="px-4 py-8 justify-center flex">
        <div className="grid gap-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div class="relative flex max-w-[24rem] flex-col overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <div class="relative m-0 overflow-hidden text-gray-700 bg-transparent rounded-none shadow-none bg-clip-border">
              <img src={blog} alt="blog" />
            </div>
            <div class="p-6">
              <h4 class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Latest News and Trends
              </h4>
              <p class="block font-sans text-md antialiased font-normal leading-relaxed text-gray-700">
                Welcome to the IT Sector Blog your go-to source for the latest
                news and insights from the world of technology...
              </p>
            </div>
            <div class="flex items-center justify-between p-4">
              <a
                href="/blog"
                className="block w-full rounded-lg bg-gray-900 py-2 text-center text-sm font-bold uppercase text-white transition-all hover:shadow-lg"
              >
                <button>Blog</button>
              </a>
            </div>
          </div>

          <div class="relative flex max-w-[24rem] flex-col overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <div class="relative m-0 overflow-hidden text-gray-700 bg-transparent rounded-none shadow-none bg-clip-border">
              <img src={ebook} alt="ebook" />
            </div>
            <div class="p-6">
              <h4 class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Navigating the Digital Frontier
              </h4>
              <p class="block   font-sans text-md antialiased font-normal leading-relaxed text-gray-700">
                This eBook takes you deep into the world of the IT sector,
                covering the latest technology trends, industry best
                practices...
              </p>
            </div>
            <div class="flex items-center justify-between p-4">
              <a
                href="/ebook"
                className="block w-full rounded-lg bg-gray-900 py-2 text-center text-sm font-bold uppercase text-white transition-all hover:shadow-lg"
              >
                <button>Ebook</button>
              </a>
            </div>
          </div>
          <div class="relative flex max-w-[24rem] flex-col overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <div class="relative m-0 overflow-hidden text-gray-700 bg-transparent rounded-none shadow-none bg-clip-border">
              <img src={mocktest} alt="mocktest" />
            </div>
            <div class="p-6">
              <h4 class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Assess Your Skills and Knowledge
              </h4>
              <p class="block   font-sans text-md antialiased font-normal leading-relaxed text-gray-700">
                Prepare for success with "IT Sector Mock Tests," a comprehensive
                eBook designed to help you assess and enhance your IT...
              </p>
            </div>
            <div class="flex items-center justify-between p-4">
              <a
                href="/mocktest"
                className="block w-full rounded-lg bg-gray-900 py-2 text-center text-sm font-bold uppercase text-white transition-all hover:shadow-lg"
              >
                <button>Mock Test</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotPressRelease;
