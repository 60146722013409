import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is MySQL?",
    answer:
      "MySQL is an open-source relational database management system (RDBMS) that uses SQL (Structured Query Language) for managing and manipulating data. It is one of the most popular databases used for web applications and is known for its reliability, scalability, and performance.",
  },
  {
    question: "What are the key features of MySQL?",
    answer:
      "MySQL has several key features, including: support for ACID (Atomicity, Consistency, Isolation, Durability) transactions, robust security features such as user authentication and access control, support for various storage engines including InnoDB and MyISAM, replication and clustering capabilities for high availability, and a wide range of built-in functions for data manipulation and analysis.",
  },
  {
    question: "How do you create a database in MySQL?",
    answer:
      "In MySQL, you can create a database using the CREATE DATABASE statement followed by the name of the database. For example, to create a database named 'mydatabase', you would use the following SQL query: CREATE DATABASE mydatabase;",
  },
  {
    question: "What is SQL?",
    answer:
      "SQL (Structured Query Language) is a standard language for interacting with relational databases such as MySQL. It allows users to perform various operations on databases, such as querying data, inserting, updating, and deleting records, creating and modifying database schemas, and defining access controls.",
  },
  {
    question: "How do you create a table in MySQL?",
    answer:
      "To create a table in MySQL, use the CREATE TABLE statement followed by the table name and a list of column definitions. For example: CREATE TABLE users (id INT AUTO_INCREMENT PRIMARY KEY, username VARCHAR(50), email VARCHAR(100));",
  },
  {
    question: "What are MySQL storage engines?",
    answer:
      "MySQL storage engines are components that handle the storage, retrieval, and management of data in MySQL tables. Some popular storage engines include InnoDB, which supports ACID transactions and foreign keys, and MyISAM, which is known for fast read operations and full-text search capabilities.",
  },
  {
    question: "How do you insert data into a MySQL table?",
    answer:
      "To insert data into a MySQL table, use the INSERT INTO statement followed by the table name, column names, and values. For example: INSERT INTO users (username, email) VALUES ('john_doe', 'john@example.com');",
  },
  {
    question: "What is a primary key in MySQL?",
    answer:
      "A primary key is a column or a set of columns that uniquely identifies each row in a table. It ensures that no duplicate values exist in the primary key column(s) and that each row can be uniquely identified. Primary keys are typically used to establish relationships between tables in a database.",
  },
  {
    question: "How do you update data in a MySQL table?",
    answer:
      "To update data in a MySQL table, use the UPDATE statement followed by the table name, SET clause with column-value pairs, and an optional WHERE clause to specify which rows to update. For example: UPDATE users SET email = 'new_email@example.com' WHERE username = 'john_doe';",
  },
  {
    question: "How do you delete data from a MySQL table?",
    answer:
      "To delete data from a MySQL table, use the DELETE FROM statement followed by the table name and an optional WHERE clause to specify which rows to delete. For example: DELETE FROM users WHERE username = 'john_doe';",
  },
  {
    question: "What is a foreign key in MySQL?",
    answer:
      "A foreign key is a column or a set of columns in one table that references the primary key columns of another table. It is used to establish and enforce a link between the data in the two tables, ensuring referential integrity. Foreign keys help maintain consistent and valid data relationships within the database.",
  },
  {
    question: "What are MySQL indexes?",
    answer:
      "Indexes in MySQL are special data structures that improve the speed of data retrieval operations on a table. They are created on columns to allow quick searches, lookups, and efficient query performance. Common types of indexes include primary key indexes, unique indexes, and full-text indexes.",
  },
  {
    question: "How do you perform a join in MySQL?",
    answer:
      "To perform a join in MySQL, use the JOIN clause in a SELECT statement to combine rows from two or more tables based on a related column. For example, an INNER JOIN: SELECT users.username, orders.order_id FROM users INNER JOIN orders ON users.id = orders.user_id;",
  },
  {
    question: "What is MySQL Workbench?",
    answer:
      "MySQL Workbench is a unified visual tool for database architects, developers, and DBAs. It provides data modeling, SQL development, and comprehensive administration tools for server configuration, user administration, backup, and more. It simplifies database design and management tasks.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function MysqlFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>MySQL FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/mysqlfaqs.jpg"
                alt="mysql"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18" // Adjusted width
                          height="18" // Adjusted height
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MysqlFAQS;
