// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from '../FreeDemoRegistration'
import FAQSCSS from '../FAQ/FAQSCSS'

export default function CssPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Dream Adoration</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Cascading Style Sheets</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              In Dream Adoration, CSS classes are offered to provide a foundational understanding of Cascading Style Sheets (CSS), which is essential for styling and designing web pages. Through these classes, participants learn the fundamentals of CSS syntax, selectors, properties, and values, enabling them to create visually appealing and responsive web layouts. Whether you're a beginner looking to start your journey in web development or an experienced professional seeking to enhance your skills, our CSS classes provide a comprehensive learning experience tailored to meet your needs. Join us and unlock the power of CSS to elevate the aesthetics and user experience of your web applications.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden" >
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration/>
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              Joining Dream Adoration offers numerous benefits as it is a versatile learning platform. Gain access to a wide array of courses covering various topics, including programming languages, web development, data science, and more. Engage with expert instructors, interactive learning materials, and hands-on projects to enhance your skills and advance your career. Whether you're a beginner or an experienced professional, Dream Adoration provides a supportive environment to foster your learning journey and achieve your goals. Join us today and unlock your potential in the world of technology and innovation.
              </p>


            </div>
          </div>
        </div>
      </div>
      <FAQSCSS/>
    </div>
  )
}
