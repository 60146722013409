import React from "react";
import { Helmet } from "react-helmet";
function InterviewMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration: Interview Questions</title>
        <meta
          name="description"
          content="Prepare for your next interview with Dream Adoration's comprehensive collection of interview questions. Explore a wide range of technical and behavioral questions to enhance your interview readiness and boost your confidence."
        />
        <meta
          name="keywords"
          content="Interview Questions, Technical Interview, Behavioral Interview, Interview Preparation, Dream Adoration, Job Interview, Career Development"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/interview" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Dream Adoration: Interview Questions"
        />
        <meta
          property="og:description"
          content="Prepare for your next interview with Dream Adoration's comprehensive collection of interview questions. Explore a wide range of technical and behavioral questions to enhance your interview readiness and boost your confidence."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration Interview Questions Cover"
        />
        <meta
          property="og:url"
          content="https://dreamadoration.com/interview-questions"
        />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration: Interview Questions"
        />
        <meta
          name="twitter:description"
          content="Prepare for your next interview with Dream Adoration's comprehensive collection of interview questions. Explore a wide range of technical and behavioral questions to enhance your interview readiness and boost your confidence."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/InterviewQuestionsCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default InterviewMeta;
