import React from 'react';
import czentrix from "../Component/Image/OurWork/c-zentrix.jpg";
import crest from "../Component/Image/OurWork/crest.jpg";
// import olmpyad from "../Component/Image/OurWork/olymapids.jpg";
import rodion from "../Component/Image/OurWork/rodion.jpg";
// import unicus from "../Component/Image/OurWork/unicus.jpg";

function OurClient() {
  return (
    <div>
      <section className="" style={{ padding: '1rem', borderRadius: '1rem', margin: '1rem', backgroundColor: '#f4f4f6' }}>

        <div className="py-4 lg:py-10 mx-auto max-w-screen-xl px-2" style={{ backgroundColor: '#0d1232', borderRadius: '10px' }}>
          <h2 className="mb-4 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl" style={{ color: 'gray' }}>Our Work's</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 justify-center text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-3 dark:text-gray-400">

            <a href="https://www.c-zentrix.com/" className="flex flex-col items-center justify-center group">
              <img src={czentrix} alt="C-zentrix" className="rounded-lg h-64 transition duration-300 transform hover:scale-110" />
              <h2 className="mt-2 text-lg hover:text-white-900 dark:hover:text-white" style={{ color: 'white' }}>C-zentrix</h2>
            </a>

            <a href="https://www.crestolympiads.com/" className="flex flex-col items-center justify-center group">
              <img src={crest} alt="Crest Olympiads" className="rounded-lg h-64 transition duration-300 transform hover:scale-110" />
              <h2 className="mt-2 text-lg hover:text-white-900 dark:hover:text-white" style={{ color: 'white' }}>Crest Olympiads</h2>
            </a>

            {/* <a href="https://www.olympiadsuccess.com/" className="flex flex-col items-center justify-center group">
              <img src={olmpyad} alt="Olympiad Success" className="rounded-lg h-64 transition duration-300 transform hover:scale-110" />
              <h2 className="mt-2 text-lg hover:text-white-900 dark:hover:text-white" style={{ color: 'white' }}>Olympiad Success</h2>
            </a>

            <a href="https://www.unicusolympiads.com/" className="flex flex-col items-center justify-center group">
              <img src={unicus} alt="Unicus Olympiads" className="rounded-lg h-64 transition duration-300 transform hover:scale-110" />
              <h2 className="mt-2 text-lg hover:text-white-900 dark:hover:text-white" style={{ color: 'white' }}>Unicus Olympiads</h2>
            </a> */}

            <a href="https://www.rodionsolutions.com/" className="flex flex-col items-center justify-center group">
              <img src={rodion} alt="Rodion Solutions" className="rounded-lg h-64 transition duration-300 transform hover:scale-110" />
              <h2 className="mt-2 text-lg hover:text-white-900 dark:hover:text-white" style={{ color: 'white' }}>Rodion Solutions</h2>
            </a>

          </div>
        </div>
      </section>
    </div>
  );
}

export default OurClient;
