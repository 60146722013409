import React from "react";
import htmlimg from "../Image/Book/python.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function PythonBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Python
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Sumita Mukherji
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Certainly, here's a description for Python: Embark on a journey
              into the world of programming excellence with 'Python Unleashed.'
              Discover the versatility and simplicity of Python, the language
              powering everything from web development to data science and
              artificial intelligence. This comprehensive guide equips you with
              the knowledge and skills to become a proficient Python programmer.
              From basic syntax to advanced topics like object-oriented
              programming and web scraping, 'Python Unleashed' covers it all.
              Whether you're a beginner or an experienced developer, this book
              is your gateway to mastering Python and unleashing your
              creativity. Elevate your coding skills, solve complex problems,
              and join the ranks of Pythonistas with 'Python Unleashed'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default PythonBook;
