import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import blogimg from "./Image/blog.jpg";
import Preloader from "./Preloader";
import BlogMeta from "./Meta/BlogMeta";
import BlogPodcast from "./Podcast/BlogPodcast";

function Blog() {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(9);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(
  //     "https://newsapi.org/v2/top-headlines?country=in&category=technology&apiKey=3975d984c4cf47eba72cf8fccf08c6fd"
  //   )
  useEffect(() => {
    // fetch("/api/news")
    fetch("redirect_file.php", {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Data: ", data); // Log the data here
        const modifiedArticles = data.articles.map((article) => ({
          ...article,
          title: limitWords(article.title, 10),
          description: limitWords(article.description, 20),
        }));
        setArticles(modifiedArticles);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
        setLoading(false);
      });
  }, []);

  const limitWords = (text, limit) => {
    if (text) {
      const words = text.split(" ");
      if (words.length > limit) {
        return words.slice(0, limit).join(" ") + "...";
      }
      return text;
    }
    return "";
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = articles.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="bg-gray-100">
      <BlogMeta />

      {loading ? (
        <Preloader />
      ) : (
        <section className="pt-20 lg:pt-30 pb-10 lg:pb-20">
          <div className="container mx-auto px-4">
            <div className="text-center mb-10">
              <span className="font-semibold text-lg text-primary block">
                Our Blogs
              </span>
              <h2 className="font-bold text-3xl sm:text-4xl md:text-5xl text-dark mb-4">
                Our Recent News
              </h2>
              <p className="text-base text-body-color">
                Stay Informed with the Latest IT Sector News on Dream Adoration
                Blogs!
              </p>
            </div>
            <BlogPodcast />
            <div className="flex flex-wrap -mx-4">
              {currentCards.map((article, index) => (
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8" key={index}>
                  <div className="bg-white rounded-lg overflow-hidden shadow-md h-full">
                    <img
                      src={article.urlToImage || blogimg}
                      alt="image"
                      className="w-full h-56 object-cover"
                    />
                    <div className="p-4">
                      <span className="bg-primary rounded-full text-white text-xs font-semibold py-1 px-2 mb-2 inline-block">
                        {new Date(article.publishedAt).toLocaleDateString()}
                      </span>
                      <h3 className="font-semibold text-lg mb-2">
                        <Link
                          to={`/blog/${index}`}
                          className="text-dark hover:text-primary"
                        >
                          {article.title}
                        </Link>
                      </h3>
                      <p className="text-sm text-gray-700 mb-4">
                        {article.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-left mt-4">
              {Array.from(
                { length: Math.ceil(articles.length / cardsPerPage) },
                (_, i) => (
                  <button
                    key={i}
                    className="mx-2 px-3 py-1 rounded bg-primary text-white"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                )
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Blog;
