import React from "react";
// import traning from './Image/traning.jpeg'
import Ourstudentsay from "./Ourstudentsay";
import LiveProjectImg from "./Image/Traning/liveproject.jpg";
import certificate from "./Image/Traning/compleation.jpg";
import mock from "./Image/Traning/mocktest.jpg";
import study from "./Image/Traning/studymaterial.jpg";
import coloaborate from "./Image/Traning/coloaborate.jpg";
import resume from "./Image/Traning/resume.jpg";
import blog from "./Image/Traning/blog.jpg";
import interview from "./Image/Traning/interview.jpg";
import TraningMeta from "./Meta/TraningMeta";

function Tranning() {
  return (
    <div style={{ marginTop: "3rem" }}>
      <TraningMeta />
      <div
        className="w-full p-4 text-center border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700"
        style={{ backgroundColor: "#f4f4f6" }}
      >
        {/* <img className="mx-auto rounded-t-lg justify-center" src={traning} alt="" /> */}
        <h5 className="mb-4 text-3xl font-bold text-black dark:text-white">
          Dream Adoration Learning{" "}
          <span style={{ color: "red" }}>
            <br />
            Internship Program
          </span>
        </h5>

        {/* <p className="mb-2 font-semibold text-white sm:text-lg text-gray-900 dark:text-gray-400">
          Dream Adoration Learning offers an internship program that gives you
          real-world experience, work on live projects, and a certificate when
          you finish. Here's what you can expect.
        </p> */}

        <div class="flex flex-wrap justify-center gap-4">
          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={certificate}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Certificate of Completion
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Receive a certificate that shows your skills and hard work.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Improve your credentials and open up more job opportunities.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={LiveProjectImg}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Work on Live Projects
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Get hands-on experience with real projects.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Learn how work is done in a real company.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={interview}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Interview Preparation
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Prepare for job interviews with special sessions.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Practice with common interview questions.
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={mock}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Mock Tests Available
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Take mock tests to evaluate your knowledge and preparation.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Use these tests to get ready for real interviews.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={study}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Study Notes Provided
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Use detailed notes to help you study and learn better
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  A well-organized note is the first step towards academic
                  success.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={coloaborate}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Collaboration
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Work with industry professionals
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Get guidance and advice from experienced mentors.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={resume}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Resume Enhancement
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Add the projects you work on to your resume.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Make your resume stand out when applying for jobs.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-52 md:rounded-none md:rounded-s-lg"
              src={blog}
              alt=""
            />
            <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                IT Sector Blog
              </h5>
              <ul class="list-disc ml-4">
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Stay updated with the latest IT trends and news.
                </li>
                <li class="mb-1 text-left font-semibold text-gray-700 dark:text-gray-400">
                  Gain insights to advance your career.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Ourstudentsay />
    </div>
  );
}

export default Tranning;
