import React, { useState, useEffect } from "react";
import Slider from "./Slider";
import Popularcourse from "./Popularcourse";
import Testimonials from "./Testimonials";
import Ourservices from "./Ourservices";
import Ourstudentsay from "./Ourstudentsay";
// import Modal from './Modal';
import Goverment from "./Goverment";
import OurClient from "./OurClient";

import Preloader from "./Preloader";
import AdsModel from "./AdsModel";
import AfterBanner from "../AfterBanner";
import GameChange from "../GameChange";
import HotPressRelease from "../HotPressRelease";
// import FAQHtml from './FAQ/FAQHtml';
// import TopPopup from './TopPopup';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or some loading process
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      {/* <AdsModel /> */}
      {/* <Modal/> */}
      <Slider />
      <AfterBanner />
      {/* <Preloader /> */}
      {/* <Toast /> */}
      {/* <TopPopup/> */}
      <Popularcourse />
      <GameChange />
      <Goverment />
      <Testimonials />

      <Ourservices />
      <HotPressRelease />
      <Ourstudentsay />
      {/* <OurClient /> */}
    </div>
  );
}

export default Home;
