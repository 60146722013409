import React from "react";
import htmlimg from "../Image/Book/bootstrap.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function BootstrapBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Bootstrap
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Dave Winer
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Absolutely, here's a description tailored for Bootstrap: Elevate
              your web development game with 'Bootstrap Essentials.' Dive into
              the power of Bootstrap and revolutionize your approach to crafting
              responsive, visually stunning websites. This indispensable guide
              provides you with the tools and techniques to streamline your
              design process and create captivating user experiences across all
              devices. Whether you're a beginner or an experienced developer,
              'Bootstrap Essentials' equips you with the knowledge and skills to
              leverage the full potential of this popular front-end framework.
              Unlock the secrets of responsive design, enhance your layouts, and
              unleash your creativity with 'Bootstrap Essentials'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default BootstrapBook;
