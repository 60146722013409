import React from "react";
import "boxicons/css/boxicons.min.css";

function Whatsapp() {
  return (
    <div>
      <a
        className="whatsapp-btn-icon bg-[#3ebd4e] text-white w-16 h-16 text-5xl rounded-full flex items-center justify-center fixed left-4 bottom-4 border-0 outline-none transition duration-500 z-[9999]"
        href="https://api.whatsapp.com/send/?phone=9097951772&amp;text&amp;type=phone_number&amp;app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="bx bxl-whatsapp"></i>
      </a>
    </div>
  );
}

export default Whatsapp;
