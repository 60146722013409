import React from "react";
import courseimg from "./Image/course.jpg";
import Ourservices from "./Ourservices";
import Popularcourse from "./Popularcourse";
import CourseMeta from "./Meta/CourseMeta";

function Course() {
  return (
    <div>
      <CourseMeta />
      <div
        id="default-carousel"
        className="relative flex items-center justify-center"
      >
        <div className="w-full rounded-lg md:w-auto">
          <img
            style={{
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
            }}
            src={courseimg}
            alt="..."
            className="object-cover object-center w-full h-full"
          />
        </div>
      </div>
      <Popularcourse />
      <Ourservices />
    </div>
  );
}

export default Course;
