import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import logo from "./Image/Dreamadoration.png";
import withPreloader from "./withPreloader"; // Import the HOC

const defaultNavigation = [
  { name: "Home", href: "/", current: true },
  {
    name: "Course",
    href: "#",
    current: false,
    subItems: [
      // { name: "Class 1", href: "/class-1" },
      // { name: "Railway", href: "/railway-course" },
      { name: "Web Development", href: "/course" },
    ],
  },
  { name: "Training", href: "/training", current: false },
  { name: "PrimeTouch", href: "/prime-touch", current: false },
  { name: "InterviewQuestion", href: "/interview", current: false },
  { name: "E-Book", href: "/ebook", current: false },
  { name: "Blog", href: "/blog", current: false },
  { name: "Contact", href: "/contact", current: false },
];

function Navbar() {
  const [navigation, setNavigation] = useState(() => {
    try {
      const storedNavigation = JSON.parse(sessionStorage.getItem("navigation"));
      return storedNavigation || defaultNavigation;
    } catch (error) {
      console.error(
        "Failed to parse navigation state from sessionStorage:",
        error
      );
      return defaultNavigation;
    }
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNavigationClick = (index) => {
    const updatedNavigation = navigation.map((item, i) => ({
      ...item,
      current: i === index,
    }));
    setNavigation(updatedNavigation);
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleBuyClick = () => {
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === "Buy" || item.name === "Mock Test",
    }));
    setNavigation(updatedNavigation);
  };

  useEffect(() => {
    sessionStorage.setItem("navigation", JSON.stringify(navigation));
  }, [navigation]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div
      className="fixed top-0 w-full z-50"
      style={{
        borderBottomRightRadius: "0.2rem",
        borderBottomLeftRadius: "0.2rem",
      }}
    >
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div
              className="mx-auto px-2 sm:px-6 lg:px-8"
              style={{ maxWidth: "90rem" }}
            >
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 items-center hidden sm:flex">
                    <img
                      className="h-10 w-auto"
                      src={logo}
                      alt="Dream Adoration"
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item, index) => (
                        <div key={item.name} className="relative">
                          {item.subItems ? (
                            <div className="group inline-block text-left">
                              <button
                                className={classNames(
                                  item.current
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "rounded-md px-3 text-sm font-medium flex items-center"
                                )}
                                onClick={() => handleDropdownToggle(index)}
                                onMouseEnter={() => handleDropdownToggle(index)}
                              >
                                {item.name}
                                <ChevronDownIcon
                                  className="ml-1 h-4 w-4"
                                  aria-hidden="true"
                                />
                              </button>
                              <div
                                className={`${
                                  dropdownOpen ? "block" : "hidden"
                                } absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 group-hover:block`}
                              >
                                <div className="py-1">
                                  {item.subItems.map((subItem) => (
                                    <a
                                      key={subItem.name}
                                      href={subItem.href}
                                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                      onClick={() =>
                                        handleNavigationClick(index)
                                      }
                                    >
                                      {subItem.name}
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "rounded-md px-3 py-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                              onClick={() => handleNavigationClick(index)}
                            >
                              {item.name}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <a
                    href="/mocktest"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                    onClick={handleBuyClick}
                  >
                    Mock Test
                  </a>
                  <a
                    href="/plan"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                    onClick={handleBuyClick}
                  >
                    Buy
                  </a>

                  {/* <a
                    href="/backend/login.php"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                    onClick={handleBuyClick}
                  >
                    Login
                  </a> */}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item, index) => (
                  <div key={item.name}>
                    {item.subItems ? (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "w-full flex justify-between items-center rounded-md px-3 py-2 text-base font-medium"
                              )}
                            >
                              <span>{item.name}</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } ml-2 h-5 w-5`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.subItems.map((subItem) => (
                                <a
                                  key={subItem.name}
                                  href={subItem.href}
                                  className="block px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => handleNavigationClick(index)}
                                >
                                  {subItem.name}
                                </a>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Disclosure.Button
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                        onClick={() => handleNavigationClick(index)}
                      >
                        {item.name}
                      </Disclosure.Button>
                    )}
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
export default withPreloader(Navbar);
