import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is Bootstrap?",
    answer:
      "Bootstrap is a free and open-source front-end framework for developing responsive and mobile-first websites and web applications. It provides a collection of CSS and JavaScript components, including grids, forms, buttons, navigation bars, and more, to simplify the process of building modern and visually appealing interfaces.",
  },
  {
    question: "What are the key features of Bootstrap?",
    answer:
      "Bootstrap has several key features, including: responsive grid system for creating flexible layouts that adapt to different screen sizes, extensive library of pre-styled UI components for rapid development, built-in support for responsive typography and utility classes, customizable themes and color schemes, and robust browser compatibility with modern web browsers.",
  },
  {
    question: "How do you include Bootstrap in a project?",
    answer:
      "To include Bootstrap in a project, you can either download the Bootstrap files from the official website and link them in your HTML file, or you can use a content delivery network (CDN) to include Bootstrap directly from a remote server. You can then use Bootstrap classes and components in your HTML markup.",
  },
  {
    question: "What is a Bootstrap grid system?",
    answer:
      "The Bootstrap grid system is a responsive, mobile-first grid layout system that allows developers to create flexible and responsive layouts for their websites and web applications. It consists of a 12-column grid that can be easily customized and adjusted to create different layouts for various screen sizes.",
  },
  {
    question: "What are Bootstrap components?",
    answer:
      "Bootstrap components are pre-styled UI elements that can be easily integrated into your web projects. Some of the most commonly used components include navigation bars, buttons, modals, alerts, cards, carousels, and forms. These components help you build a consistent and visually appealing user interface quickly.",
  },
  {
    question: "How do you customize Bootstrap?",
    answer:
      "Bootstrap can be customized by overriding the default styles with your own CSS, using the built-in SASS variables to change Bootstrap's default theme, or by using Bootstrap's customization options available on their website. You can also use third-party tools and themes to further tailor the look and feel of your project.",
  },
  {
    question: "What is Bootstrap Reboot?",
    answer:
      "Bootstrap Reboot is a collection of CSS styles that are designed to provide a more consistent baseline across browsers. It builds on Normalize.css by providing additional styles that reset browser-specific default styles, ensuring a more uniform appearance for elements across different web browsers.",
  },
  {
    question: "What are Bootstrap utility classes?",
    answer:
      "Bootstrap utility classes are a set of pre-defined CSS classes that provide common styling options, such as spacing, text alignment, colors, display properties, and more. These utility classes allow you to quickly apply styles to elements without writing custom CSS, making it easier to maintain and scale your code.",
  },
  {
    question: "How do you create a responsive navbar with Bootstrap?",
    answer:
      "To create a responsive navbar with Bootstrap, you can use the 'navbar' component along with the 'navbar-expand-*' class to control when the navbar should expand. Bootstrap provides various classes for navigation bars, including 'navbar-brand', 'navbar-toggler', 'navbar-collapse', and 'navbar-nav', to help you build a fully responsive navigation bar that adapts to different screen sizes.",
  },
  {
    question: "What are Bootstrap breakpoints?",
    answer:
      "Bootstrap breakpoints are predefined screen sizes that determine how the layout and components should adjust based on the screen width. The default breakpoints in Bootstrap are: 'xs' (extra small, <576px), 'sm' (small, ≥576px), 'md' (medium, ≥768px), 'lg' (large, ≥992px), and 'xl' (extra large, ≥1200px). These breakpoints help you create responsive designs that look good on various devices.",
  },
  {
    question: "How do you create a modal in Bootstrap?",
    answer:
      "To create a modal in Bootstrap, you can use the 'modal' component. Include a button or link with the 'data-toggle=\"modal\"' attribute to trigger the modal, and define the modal structure using the 'modal', 'modal-dialog', 'modal-content', 'modal-header', 'modal-body', and 'modal-footer' classes. You can also customize the modal with additional options and event listeners.",
  },
  {
    question: "What is the Bootstrap carousel?",
    answer:
      "The Bootstrap carousel is a slideshow component for cycling through a series of images or content. It includes support for indicators, controls, and captions. You can create a carousel using the 'carousel', 'carousel-inner', 'carousel-item', 'carousel-control-prev', 'carousel-control-next', and 'carousel-caption' classes. Carousels are often used for showcasing featured content or images on a website.",
  },
  {
    question: "How do you use Bootstrap forms?",
    answer:
      "Bootstrap provides a range of form controls, layouts, and components to help you build consistent and responsive forms. You can use classes like 'form-group', 'form-control', 'form-check', 'form-inline', and 'form-row' to structure and style your forms. Bootstrap also includes validation styles, custom checkboxes, radio buttons, and more to enhance the user experience.",
  },
  {
    question: "What is the difference between Bootstrap 4 and Bootstrap 5?",
    answer:
      "Bootstrap 5 introduces several new features and improvements over Bootstrap 4, including the removal of jQuery as a dependency, a new utility API, enhanced grid system with new gutter utilities, updated forms with more consistent styling, and improved documentation. Bootstrap 5 also includes several new components and drops support for Internet Explorer 10 and 11.",
  },
  {
    question: "How do you use Bootstrap icons?",
    answer:
      "Bootstrap Icons is a separate project that provides a collection of high-quality, open-source icons. To use Bootstrap Icons, you can include the CSS file from a CDN or download the icon files and include them in your project. You can then use the 'bi' class along with the specific icon class (e.g., 'bi-alarm') to display icons in your Bootstrap project.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function BootstrapFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>Bootstrap FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/bootstrapfaqs.jpg"
                alt="FAQ tailwind section"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 15.5L5.5 9L6.91 7.59L12 12.67L17.09 7.59L18.5 9L12 15.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BootstrapFAQS;
