import React, { useState } from "react";
import Preloader from "./Preloader";
import Toast from "./Toast"; // Import the Toast component
import $ from "jquery";

function DownloadEbookForm() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // State for toast

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(isValid ? "" : "Please enter a valid email address");
    return isValid;
  };

  const validatePhone = () => {
    const isValid = /^(\+\d{1,3}[- ]?)?\d{10}$/.test(phone);
    setPhoneError(isValid ? "" : "Please enter a valid phone number");
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    const isPhoneValid = validatePhone();

    if (isEmailValid && isPhoneValid) {
      setLoading(true);
      const pathname = window.location.pathname;
      switch (pathname) {
        case "/html-book":
          window.open("/Book/HTML.pdf", "_blank");
          break;
        case "/css-book":
          window.open("/Book/CSS.pdf", "_blank");
          break;
        case "/javascript-book":
          window.open("/Book/javascript.pdf", "_blank");
          break;
        case "/mysql-book":
          window.open("/Book/mysql.pdf", "_blank");
          break;
        case "/php-book":
          window.open("/Book/Php.pdf", "_blank");
          break;
        case "/python-book":
          window.open("/Book/Python.pdf", "_blank");
          break;
        case "/reactjs-book":
          window.open("/Book/ReactJS.pdf", "_blank");
          break;
        case "/bootstrap-book":
          window.open("/Book/Bootstrap.pdf", "_blank");
          break;
        default:
          console.log("Form submitted successfully");
          break;
      }

      $.ajax({
        url: "/web-services/handle-form-data.php",
        type: "POST",
        data: {
          email: email,
          number: phone,
          book_name: pathname,
          action: "download_book",
        },
        success: function (response) {
          setLoading(false);
          setToast({ show: true, message: response, type: "success" });
          setTimeout(() => {
            setToast({ show: false, message: "", type: "" }); // Hide the toast after 5 seconds
            window.location.reload();
          }, 3000); // Reload the page after 3 seconds
        },
        error: function (xhr, status, error) {
          setLoading(false);
          setToast({
            show: true,
            message: "Please try again after some time!",
            type: "error",
          }); // Set toast state on error
        },
      });
    } else {
      console.log("Form submission failed");
    }
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      {loading ? ( // Show preloader if loading is true
        <Preloader />
      ) : (
        <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
          <h5 className="text-2xl font-bold text-gray-900 text-center">
            Download<span style={{ color: "red" }}> E-Book</span>
          </h5>
          <div className="mb-3">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={validateEmail}
              className={`bg-gray-50 border ${
                emailError ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            />
            {emailError && (
              <p className="text-red-500 text-xs mt-1">{emailError}</p>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your Phone Number
            </label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onBlur={validatePhone}
              className={`bg-gray-50 border ${
                phoneError ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            />
            {phoneError && (
              <p className="text-red-500 text-xs mt-1">{phoneError}</p>
            )}
          </div>

          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Download
          </button>
        </form>
      )}
      {toast.show && <Toast message={toast.message} type={toast.type} />}{" "}
      {/* Render toast if show is true */}
    </div>
  );
}

export default DownloadEbookForm;
