import React from "react";
import htmlimg from "../Image/Book/reactjs.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function ReactJsBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              ReactJS
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Chris Minnick
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Here's a description for React.js: Embark on a transformative
              journey into the realm of modern web development with 'Mastering
              React.js.' Discover the power and versatility of React.js, the
              cutting-edge library revolutionizing user interfaces. This
              indispensable guide equips you with the knowledge and skills to
              build dynamic, interactive web applications with ease. Dive deep
              into component-based architecture, state management, and
              declarative UI design. Whether you're a newcomer or a seasoned
              developer, 'Mastering React.js' empowers you to unlock the full
              potential of this groundbreaking technology. Elevate your web
              development prowess, captivate your users, and redefine the future
              of the web with 'Mastering React.js'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default ReactJsBook;
