import React from "react";
import { Helmet } from "react-helmet";
function TraningMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration Training: Mastering Web Development</title>
        <meta
          name="description"
          content="Join our comprehensive web development training at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          name="keywords"
          content="Training, Web Development, HTML, CSS, JavaScript, ReactJS, Programming, Learning, Education, Dream Adoration, Web Development Training"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://dreamadoration.com/training" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Dream Adoration Training: Mastering Web Development"
        />
        <meta
          property="og:description"
          content="Join our comprehensive web development training at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration Web Development Training Cover"
        />
        <meta property="og:url" content="https://dreamadoration.com/training" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration Training: Mastering Web Development"
        />
        <meta
          name="twitter:description"
          content="Join our comprehensive web development training at Dream Adoration. Learn HTML, CSS, JavaScript, and ReactJS from industry experts and become a proficient web developer. Enroll now and start building your dream projects."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/TrainingCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default TraningMeta;
