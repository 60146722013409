// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from '../FreeDemoRegistration'
import MaterialUIFAQS from '../FAQ/MaterialUIFAQS'

export default function MaterailUIPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Dream Adoration</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Material UI</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              At Materialize Mastery, we offer comprehensive courses focused on Material-UI, a leading React component library for building visually stunning and user-friendly web interfaces. Our classes provide a deep dive into the core concepts of Material-UI, covering everything from its flexible layout system and extensive collection of components to its theming capabilities and responsive design principles.

Through hands-on exercises and interactive tutorials, participants learn how to harness the full potential of Material-UI to create modern and elegant web applications with ease. Whether you're a beginner exploring the world of React development or an experienced developer looking to streamline your UI development process, our Material-UI courses cater to individuals of all skill levels.

Join us at Materialize Mastery and embark on a journey to master Material-UI, empowering yourself to craft sophisticated and intuitive user interfaces that elevate the overall user experience of your web projects. Let Materialize Mastery be your gateway to unlocking the transformative power of Material-UI and taking your web development skills to new heights.
              </p>
            </div>
          </div>
        </div>
       <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1" style={{margin:'0rem'}}>

  <FreeDemoRegistration/>
</div>


        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              Joining Dream Adoration offers numerous benefits as it is a versatile learning platform. Gain access to a wide array of courses covering various topics, including programming languages, web development, data science, and more. Engage with expert instructors, interactive learning materials, and hands-on projects to enhance your skills and advance your career. Whether you're a beginner or an experienced professional, Dream Adoration provides a supportive environment to foster your learning journey and achieve your goals. Join us today and unlock your potential in the world of technology and innovation.
              </p>


            </div>
          </div>
        </div>
      </div>
      <MaterialUIFAQS/>
    </div>
  )
}
