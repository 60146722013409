import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is HTML?",
    answer:
      "HTML stands for HyperText Markup Language. It is the standard markup language used to create and design documents on the World Wide Web. HTML documents are interpreted by web browsers to render web pages.",
  },
  {
    question: "What are the basic elements of an HTML document?",
    answer:
      "The basic elements of an HTML document include the <html>, <head>, and <body> tags. The <html> tag defines the root of the HTML document, the <head> tag contains meta-information about the document, and the <body> tag contains the content that is displayed on the web page.",
  },
  {
    question: "How do you create a hyperlink in HTML?",
    answer:
      'To create a hyperlink in HTML, you use the <a> tag followed by the href attribute, which specifies the URL of the link. For example: <a href="https://www.example.com">Link Text</a>',
  },
  {
    question: "What is the purpose of the alt attribute in the <img> tag?",
    answer:
      "The alt attribute in the <img> tag provides alternative text for an image if the image cannot be displayed. It is also used by screen readers to describe the content of the image to visually impaired users.",
  },
  {
    question: "What is the DOCTYPE declaration in HTML?",
    answer:
      "The DOCTYPE declaration in HTML is a required preamble that specifies the HTML version used in the document. It helps the browser to render the page correctly. The declaration for HTML5 is <!DOCTYPE html>.",
  },
  {
    question: "How do you create an ordered list in HTML?",
    answer:
      "To create an ordered list in HTML, you use the <ol> tag to define the list and <li> tags for each list item. For example: <ol><li>Item 1</li><li>Item 2</li></ol>",
  },
  {
    question: "What is the difference between block-level and inline elements?",
    answer:
      "Block-level elements take up the full width available and start on a new line (e.g., <div>, <h1>, <p>). Inline elements take up only as much width as necessary and do not start on a new line (e.g., <span>, <a>, <img>).",
  },
  {
    question: "How do you create a table in HTML?",
    answer:
      "To create a table in HTML, you use the <table> tag. Inside it, you define rows using <tr>, headers using <th>, and data cells using <td>. For example: <table><tr><th>Header</th></tr><tr><td>Data</td></tr></table>",
  },
  {
    question: "What is a semantic HTML element?",
    answer:
      "Semantic HTML elements clearly describe their meaning in a human- and machine-readable way. Examples include <header>, <footer>, <article>, <section>, and <nav>. They improve the accessibility and SEO of web pages.",
  },
  {
    question: "How do you embed a video in an HTML document?",
    answer:
      'To embed a video in an HTML document, you use the <video> tag with the src attribute. You can also include controls to allow the user to play, pause, and control the volume. For example: <video src="video.mp4" controls></video>',
  },
  {
    question: "What is the purpose of the <meta> tag in HTML?",
    answer:
      "The <meta> tag provides metadata about the HTML document, such as character set, page description, keywords, author, and viewport settings. Metadata is used by browsers, search engines, and other web services.",
  },
  {
    question: "How do you create a form in HTML?",
    answer:
      'To create a form in HTML, you use the <form> tag. Inside it, you include form elements such as <input>, <textarea>, <select>, and <button>. For example: <form><input type="text" name="name"><input type="submit" value="Submit"></form>',
  },
  {
    question: "What are HTML attributes?",
    answer:
      'HTML attributes provide additional information about HTML elements. They are always included in the opening tag and usually come in name/value pairs like id="value" or class="value".',
  },
  {
    question: "How do you include a comment in an HTML document?",
    answer:
      "To include a comment in an HTML document, you use the <!-- and --> tags. Anything placed between these tags will not be rendered by the browser. For example: <!-- This is a comment -->",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function FAQHtml() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>HTML FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/htmlfaq.jpg"
                alt="html"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18"
                          height="18"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQHtml;
