import CEO from "./Image/ceo.png";

export default function Company() {
  return (
    <div style={{ backgroundColor: "#f4f4f6", padding: "1rem" }}>
      <div
        className="mx-auto lg:max-w-8xl border border-gray-200 rounded-lg shadow p-6 md:p-8"
        style={{ marginTop: "4rem", backgroundColor: "#0d1232" }}
      >
        <h5
          className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
          style={{ color: "white" }}
        >
          Your Path to Success Fulfill Your Dreams with{" "}
          <span style={{ color: "red" }}>
            <br /> Dream Adoration
          </span>
        </h5>
        <img
          className="mx-auto h-12"
          src="https://tailwindui.com/img/logos/workcation-logo-indigo-600.svg"
          alt=""
        />

        <figure className="mt-10">
          <blockquote className="text-left font-semibold text-gray-900 sm:text-xl sm:leading-6">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Wide Range of Courses and Internships
            </h5>
            <ul className="list-disc">
              <li className="mb-5 font-normal text-gray-400 dark:text-gray-400">
                Dream Adoration offers many different courses and internship
                opportunities.
              </li>
            </ul>

            <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Specialization in IT and Government Sectors
            </h5>
            <ul className="list-disc">
              <li className="mb-5 font-normal text-gray-400 dark:text-gray-400">
                It focuses on teaching about IT and Government jobs.
              </li>
            </ul>

            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Diverse Curriculum
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                The courses cover a lot of different topics for different
                careers.
              </li>
            </ul>

            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Practical Learning
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                Students learn by doing exercises and hands-on training.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Mock Tests for Real-World Preparation
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                They have tests that help you prepare for real-life challenges.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Internship Programs
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                You can work in a real job to get experience.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Prestigious Certificates
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                After you finish a course, you get a valuable certificate.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Improves Job Opportunities
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                Having this certificate helps you get a better job.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Holistic Education
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                They care about your personal and professional growth.
              </li>
            </ul>
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-300 dark:text-white">
              Empowering Students
            </h5>
            <ul class="list-disc">
              <li class="mb-5 font-normal text-gray-400 dark:text-gray-400">
                Dream Adoration helps you reach your goals and do well in life.
              </li>
            </ul>
            {/* Add more content here */}
          </blockquote>

          <figcaption className="mt-10">
            <img
              className="mx-auto h-10 w-10 rounded-full"
              src={CEO}
              alt="ceo"
              style={{ height: "7rem", width: "7rem" }}
            />

            <div className="mt-4 flex items-center justify-center space-x-3 text-base flex-col md:flex-row">
              <div className="flex flex-col items-center justify-center">
                <div className="text-gray-600">
                  <span style={{ fontWeight: "bold" }}>
                    <span className="font-bold text-gray-100">
                      Aman Kumar .
                    </span>
                    <span className="ml-2 text-gray-300">Founder & CEO</span>{" "}
                    {/* Added ml-4 for 1rem margin */}
                  </span>
                </div>
                <div className="flex gap-2">
                  <a href="https://x.com/AmanKum64063651?t=aQyUCQtFXvluP17qNdNT2w&s=08">
                    <box-icon
                      name="twitter"
                      type="logo"
                      animation="tada"
                      color="#58d9f3"
                    ></box-icon>
                  </a>
                  <a href="https://www.linkedin.com/in/aman-kumar-177815213/">
                    <box-icon
                      name="linkedin-square"
                      type="logo"
                      animation="tada"
                      color="#0077B5"
                    ></box-icon>
                  </a>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}
