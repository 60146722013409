import React from "react";

function PrivacyPolicy() {
  return (
    <section className="bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300 p-6">
      <div className="max-w-screen-lg mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-extrabold mb-6 mt-6 dark:text-white">
          Privacy Policy
        </h1>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Information Provided</h2>
          <p className="mb-4">
            While Dream Adoration shall make all reasonable efforts to update or
            correct any information that appears on the Internet or on Dream
            Adoration's platforms, we do not make any representation or warranty
            in relation to, or in any way guarantee the accuracy of information
            on the website at any given time.
          </p>
          <p className="mb-4">
            We would not be responsible for any misinformation, inconvenience,
            or financial loss or other damage caused to you due to the use of
            this website. Information is supplied upon the condition that the
            persons receiving the same shall make their own determination as to
            its suitability for their purposes prior to its use or in connection
            with the making of any decision. Any use of this website or the
            information available on this website is at your own risk.
          </p>
          <p>
            Dream Adoration excludes all liability to the fullest extent
            permissible by law whether in contract, tort (including liability
            for negligence), or otherwise for the accuracy, suitability,
            quality, or completeness of any information and the value and
            integrity of goods and services offered over www.dreamadoration.com.
            You acknowledge and agree that we have no control over it and that
            we exclude all liability for any material on the World Wide Web,
            which can be accessed by using www.dreamadoration.com.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            Limitation of Liability
          </h2>
          <p className="mb-4">
            Neither Dream Adoration and its affiliates, nor their officers,
            employees, or agents shall be liable for any loss, damage, or
            expenses arising out of any access to, use of, or reliance upon,
            this website or the information on this website, or any website
            linked to this website.
          </p>
          <p className="mb-4">
            Dream Adoration would not be responsible or liable in any manner for
            any error or defects that may exist or for any costs, loss of
            profits, or consequential losses that may arise from your use of or
            inability to use, or access or a failure, suspension, or withdrawal
            of all or part of the facilities at any time. Dream Adoration
            accepts no liability or responsibility for loss of data or breach of
            confidentiality or other consequences howsoever occurring.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">World Wide Web</h2>
          <p>
            The service is accessed via the World Wide Web, which is independent
            of www.dreamadoration.com and Dream Adoration. Your use of the World
            Wide Web is solely at your own discretion and subject to all
            applicable national and international laws, rules, and regulations.
            Dream Adoration will have no responsibility for any information or
            service obtained by you on the World Wide Web.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Links</h2>
          <p>
            This website may be linked to other websites on the World Wide Web
            that are not under the control of or maintained by Dream Adoration.
            Such links do not indicate any responsibility or endorsement on our
            part for the concerned external website, its contents, or the links
            displayed on it. These links are provided only as a convenience, in
            order to help you find relevant websites, services, and/or products
            that may be of interest to you, quickly and easily. It is your
            responsibility to decide whether any services and/or products
            available through any of these websites are suitable for your
            purposes. Dream Adoration is not responsible for any transactional
            errors, misinformation, and frauds taking place on those sites.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            Access to Password Protected/Secure Areas
          </h2>
          <p>
            Only users with login passwords can access the password-protected
            areas of www.dreamadoration.com. The user identity shall be provided
            by Dream Adoration while the user shall define the login password,
            which shall be encrypted and stored on the www.dreamadoration.com
            server. We strongly recommend that the password be retained
            confidential. The password is an authentication tool and we
            recommend that each User periodically changes the password.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Information Received</h2>
          <p className="mb-4">
            Dream Adoration may ask you to provide personal information, views,
            or comments for marketing campaigns, feedback, and the like. This
            allows us to ascertain your needs/requirements in order to enhance
            our services for you. Importantly, we only collect personal
            information about you that we consider necessary for achieving this
            purpose. Dream Adoration will not share such personal information
            with any third party in any manner which may culminate in invasion
            of your privacy. Though we take utmost care to keep all the
            information secured and confidential, Dream Adoration will not
            accept any liability arising thereof.
          </p>
          <p className="mb-4">
            Dream Adoration may divulge your personal information to Government
            bodies, Regulatory Authorities, our professional consultants, which
            may include Chartered Accountants, auditors, and lawyers, and your
            duly authorized representatives.
          </p>
          <p>
            The personal information may be divulged by Dream Adoration if it
            believes in good faith that such divulgence is in conformity with
            legal requirements or in compliance with a legal process or to
            safeguard and preserve the rights and property of Dream Adoration
            and all its other users, and for security purposes.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Financial Transactions</h2>
          <p className="mb-4">
            In case there has to be a reversal of money paid online, it would be
            entirely dependent on the payment gateway’s terms and conditions,
            and Dream Adoration would not be responsible or liable in any manner
            for quick reversal or for expediting the process.
          </p>
          <p>
            Dream Adoration would not in any way be responsible for any damage
            or loss caused to you as a result of financial transactions on the
            website. We would also not be responsible or liable in any manner if
            your credit card/net banking is misused/used fraudulently.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Alterations</h2>
          <p className="mb-4">
            Dream Adoration may suspend or vary the whole or any part of the
            service offered on www.dreamadoration.com for any reason, at any
            time at its sole discretion without prior notice. Dream Adoration
            hereby reserves the right to make subsequent changes to
            www.dreamadoration.com, or its content and services may be modified,
            supplemented, or withdrawn.
          </p>
          <p>
            You accept and agree that Dream Adoration reserves the right to
            vary, amend, or alter the terms and conditions of this contract from
            time to time as considered appropriate. Such variations will be
            deemed effective and in force immediately upon posting of the varied
            Legal Statement on www.dreamadoration.com. By continuing to use
            www.dreamadoration.com after the change in Privacy Policy, it would
            be deemed that you agree and accept such variation.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Disclaimer</h2>
          <p className="mb-4">
            Dream Adoration retains copyright on all the text, graphics, and
            trademarks displayed on this site. All the text, graphics, and
            trademarks displayed on this site are owned by Dream Adoration and
            used under license by Dream Adoration affiliates. While Dream
            Adoration attempts to provide accurate information on the website,
            it does not guarantee the accuracy, completeness, or reliability of
            any statement or any other information displayed, uploaded,
            distributed, or linked through the website. The user acknowledges
            that any reliance upon any such information or advice shall be at
            their own risk. Dream Adoration disclaims any and all warranties,
            express or implied, including any warranty as to the availability,
            accuracy, completeness, or content of information, products, or
            services. Dream Adoration is not responsible for the contents of any
            linked site or any link contained in a linked site, or any changes
            or updates of such sites. The links are provided for users' general
            convenience and inclusion of the link does not imply endorsement of
            the linked site. Dream Adoration in no event will be liable for any
            damages or losses, including without limitation indirect,
            consequential, special, incidental, or punitive damages resulting
            from or caused by the contents of this website - errors, omissions,
            and commissions in its contents as well as through consultations and
            products and other related services. Any product or software
            downloaded or third-party link accessed or otherwise obtained
            through the use of the website is done at your own discretion and
            risk, and you will be solely responsible for any damage to your
            computer system or loss of data that results from the download of
            any such material or software or access to any such third-party
            website.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Intellectual Property</h2>
          <p className="mb-4">
            The content (information, communication, images, and sounds
            contained on or available through www.dreamadoration.com) provided
            on www.dreamadoration.com by Dream Adoration, its partners and
            associates, its affiliates, independent content providers, and third
            parties, is copyrighted and all rights to the same are reserved. The
            contents of this site cannot be reproduced, modified, transferred,
            distributed, republished, downloaded, posted, or transmitted in any
            form or by any means including but not limited to electronic,
            mechanical photocopying, or recording without the prior written
            permission of Dream Adoration. You agree that the material and
            content contained within or provided by www.dreamadoration.com is
            for your personal use only and would not be used for commercial
            purposes or distributed commercially. The content of Dream Adoration
            may not be mirrored in any way on any other server without the prior
            written consent of Dream Adoration. Any unauthorized use of the
            contents of www.dreamadoration.com would result in a breach of
            copyright or trademark laws. Dream Adoration retains copyright on
            all the text, graphics, and trademarks displayed on this site. All
            the text, graphics, and trademarks displayed on this site are owned
            by Dream Adoration and used under license by Dream Adoration
            affiliates.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Trademarks</h2>
          <p>
            Dream Adoration and its logo, Dream Adoration, are trademarks or
            service marks, registered to Dream Adoration. Nothing on the Website
            may be interpreted so as to grant to you, directly or indirectly,
            the use of a trademark or service mark reproduced on the website,
            whether belonging to Dream Adoration or third-party suppliers,
            without the written permission of Dream Adoration.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Governing law</h2>
          <p>
            The Privacy Policy shall be governed and construed in accordance
            with the laws of India. The Courts of competent jurisdiction in the
            relevant circles/Delhi shall have exclusive jurisdiction arising out
            of any claims or dealing with Dream Adoration and all disputes shall
            be governed by the laws of India.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
