import React, { useState } from "react";
import coursesData from "../../Data/Course/Hindi.json"; // Import JSON data

function Hindi() {
  const [modalData, setModalData] = useState({ isOpen: false, videoSrc: "" });

  const handleFreeDemoClick = (videoSrc) => {
    setModalData({ isOpen: true, videoSrc });
  };

  const closeModal = () => {
    setModalData({ isOpen: false, videoSrc: "" });
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f6",
        margin: "1rem",
        padding: "1rem",
        borderRadius: "10px",
      }}
    >
      <div className="mx-auto mt-8 max-w-screen-xxl">
        <h3 className="text-center text-blue-900 font-bold py-4">
          Our
          <br />
          Hindi Teacher
        </h3>
        <div className="flex flex-wrap justify-center">
          {coursesData.map((course, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2">
              <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-8">
                <div className="flex justify-end px-4 pt-4">
                  <button
                    id="dropdownButton"
                    data-dropdown-toggle="dropdown"
                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                    type="button"
                  ></button>
                  <div
                    id="dropdown"
                    className="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  ></div>
                </div>
                <div className="flex flex-col items-center pb-10">
                  <img
                    className="w-24 h-24 mb-3 rounded-full shadow-lg"
                    src={course.imageUrl}
                    alt={course.title}
                  />
                  <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                    {course.name}
                  </h5>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {course.title}
                  </span>
                  <span className="text-m font-bold text-gray-900 dark:text-gray-900">
                    Rs. {course.price}
                  </span>
                  <div className="flex mt-4 md:mt-6">
                    <a
                      href="#"
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Buy Now
                    </a>
                    <button
                      onClick={() => handleFreeDemoClick(course.href)}
                      className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Free Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalData.isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-filter backdrop-blur-sm"
          onClick={closeModal}
        >
          <div
            className="relative w-full h-full sm:w-3/4 sm:h-3/4 lg:w-1/2 lg:h-1/2 p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative w-full h-full flex justify-center items-center">
              <video
                src={modalData.videoSrc}
                controls
                poster="images/post-1-1.png"
                className="w-full h-auto"
              ></video>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hindi;
