import React, { useState, useEffect } from "react";

function Toast({ message, type }) {
  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 3000); // Hide after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showToast && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-md">
          <div className="bg-white p-4 rounded-lg shadow-lg text-gray-500 dark:bg-gray-800 flex items-center">
            <div
              className={`rounded-lg mr-2 ${
                type === "success"
                  ? "bg-green-100 text-green-500"
                  : "bg-red-100 text-red-500"
              }`}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {type === "success" ? (
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.707a1 1 0 00-1.414 0L9 11.586 7.707 10.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 000-1.414z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm2.828-11.828a1 1 0 00-1.414-1.414L10 8.172 8.586 6.758a1 1 0 00-1.414 1.414L8.172 10l-1.586 1.586a1 1 0 001.414 1.414L10 11.828l1.414 1.414a1 1 0 001.414-1.414L11.828 10l1.414-1.414z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
            </div>
            <span className="sr-only">Icon</span>
            <div className="text-sm font-normal flex-grow">{message}</div>
            <button
              type="button"
              className="bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              onClick={() => setShowToast(false)}
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Toast;
