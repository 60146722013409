import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is CSS?",
    answer:
      "CSS stands for Cascading Style Sheets. It is a styling language used to describe the presentation of a document written in HTML. CSS describes how elements should be rendered on screen, on paper, in speech, or on other media.",
  },
  {
    question: "What are the different types of CSS?",
    answer:
      "There are three main types of CSS: Inline CSS, Internal CSS, and External CSS. Inline CSS is applied directly to an HTML element using the style attribute. Internal CSS is defined within the <style> element in the <head> section of an HTML document. External CSS is stored in separate CSS files and linked to HTML documents using the <link> element.",
  },
  {
    question: "What are CSS selectors?",
    answer:
      "CSS selectors are patterns used to select and style elements in an HTML document. They specify which elements should be styled based on their element type, class, ID, attributes, or relationship with other elements. Common CSS selectors include element selectors, class selectors, ID selectors, attribute selectors, and descendant selectors.",
  },
  {
    question: "What is the box model in CSS?",
    answer:
      "The CSS box model is a fundamental concept that describes how elements are rendered on a web page. It consists of content, padding, border, and margin. The content area contains the actual content of the element. Padding is the space between the content and the border. Border surrounds the padding and content. Margin is the space outside the border, which creates space between elements.",
  },
  {
    question: "What is the difference between padding and margin?",
    answer:
      "Padding is the space between the content and the border of an element, while margin is the space outside the border of an element. Padding adds space inside the element, increasing its size, whereas margin creates space between the element and its neighboring elements.",
  },
  {
    question: "How do you center an element using CSS?",
    answer:
      "To center an element horizontally, you can use the 'margin: auto' property if the element has a defined width. For vertical centering, you can use flexbox with 'display: flex', 'align-items: center', and 'justify-content: center' properties. Another method is to use CSS Grid with 'display: grid' and 'place-items: center'.",
  },
  {
    question: "What are CSS pseudo-classes and pseudo-elements?",
    answer:
      "CSS pseudo-classes are used to define the special state of an element, such as ':hover' for when an element is being hovered over. Pseudo-elements are used to style specific parts of an element, such as '::before' and '::after', which insert content before or after the element's actual content.",
  },
  {
    question: "What is a CSS preprocessor?",
    answer:
      "A CSS preprocessor is a scripting language that extends CSS and compiles it into regular CSS. Popular preprocessors include Sass (Syntactically Awesome Stylesheets) and LESS. They provide features like variables, nesting, mixins, and functions to make writing CSS more efficient and maintainable.",
  },
  {
    question: "How do you create a CSS transition?",
    answer:
      "To create a CSS transition, you use the 'transition' property to specify the property to be animated, the duration of the animation, the timing function, and the delay (optional). For example: 'transition: background-color 0.3s ease-in-out;' will smoothly animate the background color change over 0.3 seconds.",
  },
  {
    question: "What are media queries in CSS?",
    answer:
      "Media queries are used to apply different CSS styles based on the characteristics of the device or viewport, such as its width, height, resolution, or orientation. They enable responsive design by allowing you to create styles that adapt to different screen sizes. For example: '@media (max-width: 600px) { ... }' applies styles when the viewport is 600 pixels or narrower.",
  },
  {
    question: "What is Flexbox in CSS?",
    answer:
      "Flexbox, or the Flexible Box Layout, is a CSS layout module designed to provide a more efficient way to lay out, align, and distribute space among items in a container. It provides a set of properties, such as 'display: flex', 'justify-content', 'align-items', and 'flex-direction', to create flexible and responsive layouts.",
  },
  {
    question: "How do you use CSS Grid?",
    answer:
      "CSS Grid is a powerful layout system that allows you to create complex, responsive grid-based layouts. To use CSS Grid, you define a container element with 'display: grid', set up rows and columns using 'grid-template-rows' and 'grid-template-columns', and place child elements using 'grid-row' and 'grid-column'.",
  },
  {
    question: "What is z-index in CSS?",
    answer:
      "The 'z-index' property in CSS specifies the stack order of elements along the z-axis (depth). Elements with a higher 'z-index' value are displayed in front of those with a lower value. 'z-index' only works on positioned elements (those with a position value other than 'static').",
  },
  {
    question:
      "What is the difference between 'position: absolute' and 'position: relative' in CSS?",
    answer:
      "'position: absolute' positions an element relative to its nearest positioned ancestor, removing it from the document flow. 'position: relative' positions an element relative to its normal position in the document flow, allowing it to be offset without affecting the layout of surrounding elements.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function FAQSCSS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>CSS FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/cssfaq.jpg"
                alt="css"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18"
                          height="18"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQSCSS;
