import React from "react";
import question from "../Data/InterviewQuestion/JavaScriptQuestion.json"; // Import JSON data

function JavaScriptInterviewQuestion() {
  return (
    <div style={{ margin: "2rem" }}>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto lg:py-16 lg:px-6">
          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-3xl tracking-tight font-bold text-gray-900 dark:text-white text-center">
              Unlocking the Secrets of{" "}
              <span className="font-bold text-red-500">JAVA SCRIPT </span>
              Dive into Interview Questions!
            </h2>

            {question.map((q, index) => (
              <div key={index}>
                <p className="mb-2 font-medium md-5">{q.Question}</p>
                <p className="mb-2 ">{q.Answer}</p>
                <hr />
              </div>
            ))}
          </div>
          <div style={{ display: "ruby-text" }}>
            <a href="/javascript-mock-test">
              <button
                type="button"
                class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Go To Excersise
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JavaScriptInterviewQuestion;
