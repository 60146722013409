// RotatingAnimation.js
import React from "react";
import outerCircle from "./Image/outerCircle.png"; // Ensure you have this image in your project
import character from "./Image/character.png"; // Ensure you have this image in your project
import "./CSS/ImageRotate.css"; // Ensure you have this CSS file

const RotatingAnimation = () => {
  return (
    <div className="characterAnimation">
      <img src={outerCircle} className="outerCircle" alt="Outer Circle" />
      <img src={character} className="character" alt="Character" />
    </div>
  );
};

export default RotatingAnimation;
