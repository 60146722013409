import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/testimonial.css";

const data = [
  {
    name: "Aman",
    img: "Image/testimonials/people.png",
    alt: "image1",
    review:
      'Dream Adoration empowers employees engaged in its platform to provide valuable feedback. As a learning platform, it serves as a catalyst for growth and development, fostering a culture of continuous improvement. Through constructive feedback, employees contribute to the enhancement of their own skills and the overall effectiveness of the platform, ultimately driving professional advancement and organizational success."',
  },
  {
    name: "Atulika",
    img: "Image/testimonials/human.png",
    alt: "image1",
    review:
      "Employees at Dream Adoration find the platform to be a valuable resource for honing their skills and expertise. The interactive learning environment encourages continuous improvement and knowledge sharing among peers. With user-friendly features and personalized learning paths, Dream Adoration empowers employees to explore new opportunities, enhance their capabilities, and thrive in their professional endeavors.",
  },
  {
    name: "Ram",
    img: "Image/testimonials/man.png",
    alt: "image1",
    review:
      "Working with Dream Adoration has been an enriching experience for our team. The platform offers a seamless learning journey, with a plethora of resources and tools at our disposal. The supportive community and engaging content have significantly contributed to our growth and development. Dream Adoration truly stands out as a catalyst for continuous learning and career advancement.",
  },
  {
    name: "Rashmi",
    img: "Image/testimonials/avatar.png",
    alt: "image1",
    review:
      "Dream Adoration facilitates a collaborative environment where employees actively engage in sharing feedback. This dynamic feedback loop cultivates a culture of learning and development, empowering individuals to refine their skills and contribute to the collective success of the platform. By fostering open communication and embracing diverse perspectives, Dream Adoration empowers employees to reach their full potential and drive innovation within the organization.",
  },
];

function Testimonials() {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Initially show 3 slides
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for desktop
        settings: {
          slidesToShow: 3, // Show 3 slides on desktop
        },
      },
      {
        breakpoint: 768, // Breakpoint for tablet/mobile
        settings: {
          slidesToShow: 1, // Show 1 slide on tablet/mobile
        },
      },
    ],
  };

  const toggleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index); // Toggle expanded state
  };

  return (
    <div
      className="testimonial-container"
      style={{
        backgroundColor: "#0d1232",
        padding: "2rem",
        margin: "3rem",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      <h2 className="text-white" style={{ fontSize: "18px" }}>
        Testimonial
        <br />
        <span style={{ fontSize: "2rem" }}>
          Don't just take it <span style={{ color: "red" }}>from us</span>
        </span>
      </h2>
      <div className="w-3/4 m-auto">
        <div className="mt-10">
          <Slider {...settings}>
            {data.map((d, index) => (
              <div
                key={index}
                className="slide-testimonial bg-white text-black rounded-xl overflow-hidden"
                style={{ height: "400px" }}
              >
                {/* Set fixed height */}
                <div className="h-46 rounded-t-xl bg-indigo-500 flex justify-center items-center">
                  <img src={d.img} alt="" className="h-44 w-44 rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center gap-1 p-4 overflow-auto">
                  {/* Add overflow-auto */}
                  <p className="text-xl font-semibold">{d.name}</p>
                  {expandedIndex === index ? (
                    <p>{d.review}</p>
                  ) : (
                    <p>
                      {d.review.length > 80
                        ? d.review.slice(0, 80) + "..."
                        : d.review}
                    </p> // Render shortened review
                  )}
                  <button
                    onClick={() => toggleExpand(index)}
                    className="bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl read-more-button"
                  >
                    {expandedIndex === index ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
