// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from '../FreeDemoRegistration'
import ReactJSFAQS from '../FAQ/ReactJSFAQS'

export default function ReactJsPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Dream Adoration</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">React JS</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              In Dream Adoration, React.js courses are available to offer a fundamental understanding of React.js, which is crucial for developing dynamic and interactive user interfaces. Throughout these courses, participants delve into React.js fundamentals, including JSX syntax, component-based architecture, state management, and lifecycle methods. This equips them to construct modern, responsive web applications efficiently. Whether you're a novice embarking on your web development journey or a seasoned professional looking to refine your skills, our React.js courses provide a comprehensive learning experience tailored to cater to your requirements. Join us to harness the potential of React.js and craft captivating and interactive web applications.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden" >
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration/>
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              Joining Dream Adoration offers numerous benefits as it is a versatile learning platform. Gain access to a wide array of courses covering various topics, including programming languages, web development, data science, and more. Engage with expert instructors, interactive learning materials, and hands-on projects to enhance your skills and advance your career. Whether you're a beginner or an experienced professional, Dream Adoration provides a supportive environment to foster your learning journey and achieve your goals. Join us today and unlock your potential in the world of technology and innovation.
              </p>


            </div>
          </div>
        </div>
      </div>
      <ReactJSFAQS/>
    </div>
  )
}
