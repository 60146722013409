import React, { useState } from "react";

import coursesData from "./Data/Goverment.json"; // Import JSON data
import "./CSS/popularcourse.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
function Goverment() {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleExpandClick = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f6",
        margin: "1rem",
        padding: "1rem",
        borderRadius: "10px",
      }}
    >
      <h3 id="government" className="text-center text-blue-900 font-bold py-4 ">
        Government Pocket Test
      </h3>

      <div className="cards-container">
        {coursesData.map((course, index) => (
          <Card
            key={index}
            className="w-full max-w-[20rem] shadow-lg "
            id={course.id}
          >
            <a href={course.href}>
              <CardHeader floated={false} color="blue-gray">
                <img src={course.imageUrl} alt={course.title} />
                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
              </CardHeader>

              <CardBody>
                <div className="mb-3 flex items-center justify-between">
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="font-medium"
                  >
                    {course.title}
                  </Typography>
                </div>

                <Typography
                  style={{ fontWeight: "400" }}
                  color="gray"
                  className={expandedIndex === index ? "expanded" : "collapsed"}
                >
                  {course.description}
                </Typography>
              </CardBody>
            </a>
            <CardFooter
              className="pt-0"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                style={{
                  padding: "0.5rem 1rem",
                  fontSize: "12px",
                  fontWeight: "bold",
                  backgroundColor: "#0d1232",
                }}
                onClick={() => handleExpandClick(index)}
              >
                {expandedIndex === index ? "Show Less" : "Read More"}
              </Button>
              <a href={course.href}>
                <Button
                  className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  style={{
                    padding: "0.5rem 1rem",
                    fontSize: "12px",
                    fontWeight: "bold",
                    backgroundColor: "#455073",
                  }}
                >
                  {" "}
                  Click Me
                </Button>
              </a>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Goverment;
