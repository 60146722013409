import React from 'react'

function TermsCondition() {
  return (

    <div style={{backgroundColor:'#0d1232', padding:''}}>
    <div style={{margin:'1rem', marginTop:'4rem'}}>
      <div class="bg-gray-100" style={{borderRadius:'1rem', backgroundColor:'#f4f4f6', color:'black'}}>
    <div class="container mx-auto px-4 py-8">
        <h1 class="text-2xl font-bold mb-4" style={{textAlign:'center'}}>INTERNSHIP TERMS AND CONDITIONS</h1>

        <p class="mb-4">
        This internship terms and conditions document sets out the guidelines and expectations for interns participating in Dream Adoration's internship program.
        </p>

        <h2 class="text-xl font-bold mb-2"> Internship Duration</h2>



        <ul class="list-disc list-inside mb-4">
            <li>The duration of the internship will be agreed upon between Dream Adoration and the intern, typically ranging from one to three months.</li>
            <li>Interns must adhere to the agreed-upon start and end dates of the internship.</li>
        </ul>

        <h2 class="text-xl font-bold mb-2">Internship Responsibilities</h2>

        <ul class="list-disc list-inside mb-4">
            <li> Interns will be assigned tasks and projects relevant to their field of study or interest.</li>
            <li> Interns are expected to comply with all company policies, procedures, and guidelines throughout the internship period.</li>
        </ul>

        <h2 class="text-xl font-bold mb-2">Supervision</h2>
        <ul class="list-disc list-inside mb-4">
        <li>
        Interns will work under the guidance and supervision of designated mentors or managers.
        </li>

        <li> Interns are encouraged to seek guidance and support from their supervisors whenever necessary.</li>
</ul>
        <h2 class="text-xl font-bold mb-2">Confidentiality</h2>
        <ul class="list-disc list-inside mb-4">
        <li>
            A cookie is a small file that asks permission to be placed on your computer's hard drive. Interns must maintain the confidentiality of any proprietary information or trade secrets they may come across during their internship.
        </li>

        <li>
        Interns shall not disclose any confidential information to third parties without prior authorization from Dream Adoration.
        </li>
</ul>
        <h2 class="text-xl font-bold mb-2">Compensation</h2>
        <ul class="list-disc list-inside mb-4">
        <li>
        Interns may be eligible for stipends or other forms of compensation as per the company's policies.
        </li>
        <li>
        Compensation terms, if any, will be communicated to the intern at the beginning of the internship.
        </li>
        </ul>

        <h2 class="text-xl font-bold mb-2">Evaluation</h2>
        <ul class="list-disc list-inside mb-4">
        <li>
        Interns will undergo periodic evaluations to assess their performance and progress.
        </li>
        <li>Feedback and performance reviews will be provided to interns to help them improve their skills and abilities.</li>
</ul>
        <h2 class="text-xl font-bold mb-2"> Termination</h2>
        <ul class="list-disc list-inside mb-4">
            <li>Dream Adoration reserves the right to terminate the internship at any time for reasons such as misconduct, poor performance, or violation of company policies.</li>

            <li>Interns may also terminate the internship by providing written notice to Dream Adoration, subject to any agreed-upon notice period.</li>
        </ul>

        <h2 class="text-xl font-bold mb-2">Code of Conduct</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns are expected to conduct themselves in a professional manner at all times and adhere to the company's code of conduct.</li>
    <li>Any form of harassment, discrimination, or unethical behavior will not be tolerated and may result in immediate termination of the internship.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Intellectual Property</h2>
<ul class="list-disc list-inside mb-4">
    <li>Any work, creations, or inventions developed by interns during the course of the internship will be the property of Dream Adoration.</li>
    <li>Interns agree to assign all rights, titles, and interests in any intellectual property created during the internship to Dream Adoration.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Attendance and Punctuality</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns are required to maintain regular attendance and arrive on time for scheduled work hours.</li>
    <li>Any absences or tardiness must be reported to the intern's supervisor in advance, except in cases of emergency.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Confidentiality Agreement</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns may be required to sign a separate confidentiality agreement, if deemed necessary by Dream Adoration, to protect sensitive information.</li>
    <li>Breach of confidentiality may result in legal action and termination of the internship.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Training and Development</h2>
<ul class="list-disc list-inside mb-4">
    <li>Dream Adoration may provide training and development opportunities to interns as deemed appropriate.</li>
    <li>Interns are encouraged to actively participate in training sessions and take advantage of learning opportunities provided by the company.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Health and Safety</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns must adhere to all health and safety protocols and guidelines established by Dream Adoration.</li>
    <li>Any concerns or hazards should be reported to the intern's supervisor or the designated health and safety officer immediately.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Non-Disclosure Agreement (NDA)</h2>
<ul class="list-disc list-inside mb-4">
    <li> Interns may be required to sign a non-disclosure agreement (NDA) to protect confidential information disclosed during the internship.</li>
    <li>Violation of the NDA may result in legal consequences and termination of the internship.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Conflict of Interest</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns must disclose any potential conflicts of interest that may arise during the course of the internship.</li>
    <li>Dream Adoration reserves the right to address and mitigate any conflicts of interest that may arise.</li>
</ul>

<h2 class="text-xl font-bold mb-2">Feedback and Evaluation</h2>
<ul class="list-disc list-inside mb-4">
    <li>Interns will receive constructive feedback and performance evaluations from their supervisors periodically.</li>
    <li>Interns are encouraged to provide feedback on their internship experience to help improve the program for future interns.</li>
</ul>

        <h2 class="text-xl font-bold mb-2">Agreement to Terms</h2>
        <p class="mb-4">
            By participating in Dream Adoration's internship program, interns acknowledge that they have read, understood, and agree to abide by the terms and conditions outlined in this document.
        </p>

        <h2 class="text-xl font-bold mb-2">Changes to Terms</h2>
        <p class="mb-4">
        Dream Adoration reserves the right to modify or update these internship terms and conditions at any time without prior notice.
        </p>

    </div>
</div>
    </div>
    </div>
  )
}

export default TermsCondition