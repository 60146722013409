import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// FAQ Data
const faqData = [
  {
    question: "What is PHP?",
    answer:
      "PHP is a server-side scripting language designed for web development. It is widely used for creating dynamic web pages and web applications. PHP code is executed on the server, generating HTML content that is then sent to the client's web browser for display.",
  },
  {
    question: "What are the key features of PHP?",
    answer:
      "PHP has several key features, including: being open-source and free to use, support for a wide range of databases such as MySQL, PostgreSQL, and SQLite, integration with various web servers like Apache and Nginx, extensive built-in functions for tasks such as file handling, database manipulation, and string manipulation, and support for object-oriented programming.",
  },
  {
    question: "How do you start a PHP script?",
    answer:
      "In PHP, a script can be started by embedding PHP code within the HTML code using opening and closing PHP tags. The opening tag is <?php and the closing tag is ?>. Any PHP code placed between these tags will be executed by the server when the page is requested.",
  },
  {
    question: "What is a PHP variable?",
    answer:
      "A variable in PHP is used to store data temporarily for later use in the script. Variables in PHP start with the $ symbol followed by the variable name. PHP variables are loosely typed, meaning they do not require explicit declaration of data types and can hold values of different types such as strings, numbers, arrays, or objects.",
  },
  {
    question: "How do you create a function in PHP?",
    answer:
      "To create a function in PHP, use the function keyword followed by the function name and parentheses. For example: function myFunction() { // code to be executed }.",
  },
  {
    question: "What is an array in PHP?",
    answer:
      "An array in PHP is a data structure that can store multiple values in a single variable. PHP supports both indexed arrays (where the index is a number) and associative arrays (where the index is a string).",
  },
  {
    question: "How do you connect to a MySQL database in PHP?",
    answer:
      "To connect to a MySQL database in PHP, you can use the mysqli_connect() function or the PDO extension. For example, using mysqli: $conn = mysqli_connect('hostname', 'username', 'password', 'database');.",
  },
  {
    question: "What are PHP sessions?",
    answer:
      "PHP sessions are a way to store information (in variables) to be used across multiple pages. Unlike cookies, the information is not stored on the user's computer. A session is started with the session_start() function.",
  },
  {
    question: "What is the difference between include and require in PHP?",
    answer:
      "Both include and require are used to include files in PHP, but they handle errors differently. include produces a warning but the script will continue execution, whereas require produces a fatal error and stops the script if the file cannot be included.",
  },
  {
    question: "What is PHP used for?",
    answer:
      "PHP is used for server-side scripting, command-line scripting, and writing desktop applications. Its main use is for creating dynamic and interactive web pages.",
  },
  {
    question: "What is a PHP constant?",
    answer:
      "A constant is an identifier (name) for a simple value. The value cannot be changed during the script. By default, a constant is case-sensitive. Constants are defined using the define() function.",
  },
  {
    question: "How do you handle errors in PHP?",
    answer:
      "PHP provides several ways to handle errors, such as using the die() function to output a message and exit the script, custom error handlers with set_error_handler(), and error logging with the error_log() function.",
  },
  {
    question: "What is an associative array in PHP?",
    answer:
      "An associative array is an array where each key has its specific value. Instead of accessing the data in an associative array using a numeric index, you use a label or key to identify the data.",
  },
  {
    question: "How do you redirect a page in PHP?",
    answer:
      "To redirect a page in PHP, you can use the header() function. For example: header('Location: http://www.example.com'); exit();.",
  },
];

// Generate FAQ Schema
const generateFaqSchema = (faqData) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq, index) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
};

function PHPFAQS() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqSchema = generateFaqSchema(faqData);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqSchema);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [faqSchema]);

  return (
    <div>
      <Helmet>
        <title>PHP FAQ</title>
      </Helmet>
      <style>
        {`
          .accordion-toggle {
            transition: color 0.3s ease;
          }
          .accordion-toggle svg {
            transition: transform 0.3s ease;
          }
          .accordion.active .accordion-toggle svg {
            transform: rotate(180deg);
          }
        `}
      </style>
      <section
        className="py-2"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <div
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          style={{ margin: "2rem" }}
        >
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            <div className="w-full lg:w-1/2">
              <img
                style={{ borderRadius: "0.5rem" }}
                src="Image/FAQS/phpfaqs.jpg"
                alt="php"
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  <h6 className="text-2xl text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    FAQS
                  </h6>
                  <h2 className="text-2xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    Looking for answers?
                  </h2>
                </div>
                <div
                  className="accordion-group"
                  data-accordion="default-accordion"
                >
                  {faqData.map((faq, index) => (
                    <div
                      className={`accordion pb-8 border-b border-solid border-gray-200 ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                        aria-controls={`basic-collapse-${index}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5 style={{ textAlign: "left", fontSize: "1rem" }}>
                          {faq.question}
                        </h5>
                        <svg
                          className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 ${
                            activeAccordion === index ? "rotate-180" : ""
                          }`}
                          width="18" // Adjusted width
                          height="18" // Adjusted height
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id={`basic-collapse-${index}`}
                        className={`accordion-content w-full px-0 overflow-hidden pr-4 ${
                          activeAccordion === index ? "max-h-full" : "max-h-0"
                        }`}
                        style={{ transition: "max-height 0.3s ease-in-out" }}
                        aria-labelledby={`basic-heading-${index}`}
                      >
                        <p className="text-base font-normal text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PHPFAQS;
