import React from "react";
import { Helmet } from "react-helmet";
function BlogMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration: IT Sector News Blog</title>
        <meta
          name="description"
          content="Stay updated with the latest news and developments in the IT sector with Dream Adoration's comprehensive blog. Explore daily updates, insights, and analysis on trending topics, innovations, and breakthroughs in the world of technology."
        />
        <meta
          name="keywords"
          content="IT Sector News, Technology News, IT Blog, Tech Blog, Dream Adoration, IT Trends, Technology Trends, IT Innovations, Technology Developments"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/blog" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Dream Adoration: IT Sector News Blog"
        />
        <meta
          property="og:description"
          content="Stay updated with the latest news and developments in the IT sector with Dream Adoration's comprehensive blog. Explore daily updates, insights, and analysis on trending topics, innovations, and breakthroughs in the world of technology."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:alt"
          content="Dream Adoration IT Sector News Blog Cover"
        />
        <meta
          property="og:url"
          content="https://dreamadoration.com/it-news-blog"
        />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration: IT Sector News Blog"
        />
        <meta
          name="twitter:description"
          content="Stay updated with the latest news and developments in the IT sector with Dream Adoration's comprehensive blog. Explore daily updates, insights, and analysis on trending topics, innovations, and breakthroughs in the world of technology."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/ITNewsBlogCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default BlogMeta;
