import React from "react";
import { Helmet } from "react-helmet";
function EbookMeta() {
  return (
    <div>
      <Helmet>
        <title>
          Dream Adoration E-Book: A Journey of Dream Interpretation and
          Adoration
        </title>
        <meta
          name="description"
          content="Discover the ultimate guide to Dream Adoration, an insightful e-book that explores the depths of dream interpretation and adoration. Unlock the secrets of your dreams and enhance your spiritual journey with our comprehensive guide."
        />
        <meta
          name="keywords"
          content="E-Book, Dream Adoration, Dream Interpretation, Spiritual Journey, Dream Guide, Adoration, Dream Analysis, Dream Meanings, Self-Discovery, Personal Growth"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://dreamadoration.com/ebook" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="book" />
        <meta
          property="og:title"
          content="Dream Adoration E-Book: A Journey of Dream Interpretation and Adoration"
        />
        <meta
          property="og:description"
          content="Discover the ultimate guide to Dream Adoration, an insightful e-book that explores the depths of dream interpretation and adoration. Unlock the secrets of your dreams and enhance your spiritual journey with our comprehensive guide."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta property="og:image:alt" content="Dream Adoration E-Book Cover" />
        <meta property="og:url" content="https://dreamadoration.com/ebook" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dream Adoration E-Book: A Journey of Dream Interpretation and Adoration"
        />
        <meta
          name="twitter:description"
          content="Discover the ultimate guide to Dream Adoration, an insightful e-book that explores the depths of dream interpretation and adoration. Unlock the secrets of your dreams and enhance your spiritual journey with our comprehensive guide."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default EbookMeta;
