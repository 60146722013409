import React, { useState, useEffect } from "react";
import Preloader from "./Preloader";

const withPreloader = (WrappedComponent) => {
  return (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Function to hide the loader after 2 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); // 2000 milliseconds = 2 seconds

      // Clean up the timer
      return () => clearTimeout(timer);
    }, []);

    if (loading) {
      return <Preloader />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withPreloader;
