import React, { useState, useEffect } from "react";
import questions from "../Data/Goverment/Railway/GroupA.json";

const RailwayMockTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    new Array(questions.length).fill("")
  );
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(7200); // 120 minutes in seconds
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [timerColor, setTimerColor] = useState("#00FF00"); // Initial color green
  const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!timerPaused) {
        // Only decrement time if timer is not paused
        setTimeLeft(timeLeft - 1);

        // Update timer color based on remaining time
        if (timeLeft <= 300 && timeLeft > 120) {
          setTimerColor("#FFFF00"); // Yellow
        } else if (timeLeft <= 120) {
          setTimerColor("darkred"); // Red
        }

        // Check if time is up
        if (timeLeft === 0) {
          setShowScore(true);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, timerPaused]);

  const handleOptionSelect = (option) => {
    // Check if the selected option is different from the previously selected option
    if (selectedOptions[currentQuestion] !== option) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[currentQuestion] = option;
      setSelectedOptions(newSelectedOptions);

      if (!selectedOptions[currentQuestion]) {
        // Increment attempted questions only if the current question didn't have an attempt before
        setAttemptedQuestions(attemptedQuestions + 1);
      }

      if (option === questions[currentQuestion].correctAnswer) {
        setScore(score + 1);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handlePauseResumeTimer = () => {
    setTimerPaused(!timerPaused); // Toggle timer pause/resume state
  };

  return (
    <div
      className="container mx-auto p-4"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          style={{
            backgroundColor: "#0b102b",
            borderRadius: "10px",
            color: "white",
          }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            RAILWAY GROUP-A POCKET TEST
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default RailwayMockTest;
