import React from "react";
import htmlimg from "../Image/Book/css.png";
import DownloadEbookForm from "../DownloadEbookForm";

function CssBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h5 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Mastering CSS for Modern Web Design
            </h5>
            <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
              Author : Aman Kumar
            </p>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Dress up your web creations with 'CSS Mastery,' your key to
              unlocking the visual potential of HTML. This essential resource
              equips you with the skills to style your websites with flair and
              finesse. Delve into the art of CSS and transform ordinary web
              pages into stunning visual experiences. Whether you're a newcomer
              or a seasoned coder, 'CSS Mastery' is your guide to mastering the
              intricacies of cascading style sheets. Elevate your designs,
              captivate your audience, and make your mark on the web with 'CSS
              Mastery'
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default CssBook;
