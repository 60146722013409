import React from 'react';
import ourservice from './Data/Ourservices.json'; // Import JSON data

function Ourservices() {
  return (
    <div style={{ backgroundColor: '#f4f4f6', margin:'1rem', borderRadius:'10px'}}>
      <h2 className='text-black' style={{fontSize:'18px', textAlign:'center', padding:'1rem'}}>Empowering Minds, Igniting Possibilities:<br/><span style={{fontSize:'2rem'}}>Explore the Future with <span style={{color:'red'}}>Dream Adoration</span></span></h2>
    <div className="flex flex-wrap justify-center">
      {ourservice.map((service, index) => (
        <div key={index} className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-4">
          <svg className="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d={service.icon} />
          </svg>
          <a href={service.href}>
          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{service.title}</h5>
          <p style={{fontWeight:'400'}} className="mb-3 font-normal text-gray-500 dark:text-gray-400">{service.description}</p>
          <span className="inline-flex items-center text-blue-600 hover:underline">
            {service.linkText}
          </span>
          </a>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Ourservices;
